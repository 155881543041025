var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { SkuCommodityCode } from "./SkuCommodityCode";
import { Money } from "./../Common/Money";
export class Sku {
  constructor(_data) {
    /**
     */
    __publicField(this, "CommodityCodes");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "ItemIdentifier");
    /**
     */
    __publicField(this, "Price");
    /**
     */
    __publicField(this, "SellerEntityId");
    /**
     */
    __publicField(this, "SkuId");
    /**
     */
    __publicField(this, "Type");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof Sku
     */
    __publicField(this, "$type", "V1.ComplianceMasterData.Sku");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      if (_data["CommodityCodes"] && _data["CommodityCodes"].constructor === Array) {
        this.CommodityCodes = [];
        for (let item of _data["CommodityCodes"]) {
          const commodityCodes_ = new SkuCommodityCode();
          this.CommodityCodes.push(commodityCodes_.init(item));
        }
      }
      this.Id = _data["Id"];
      this.ItemIdentifier = _data["ItemIdentifier"];
      if (_data["Price"]) {
        const price_ = new Money();
        this.Price = price_.init(_data["Price"]);
      }
      this.SellerEntityId = _data["SellerEntityId"];
      this.SkuId = _data["SkuId"];
      this.Type = SkuType[_data["Type"]];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    if (this.CommodityCodes && this.CommodityCodes.constructor === Array) {
      _data["CommodityCodes"] = [];
      for (let item of this.CommodityCodes) {
        _data["CommodityCodes"].push(item.toJSON());
      }
    }
    _data["Id"] = this.Id;
    _data["ItemIdentifier"] = this.ItemIdentifier;
    _data["Price"] = this.Price ? this.Price.toJSON() : void 0;
    _data["SellerEntityId"] = this.SellerEntityId;
    _data["SkuId"] = this.SkuId;
    _data["Type"] = SkuType[this.Type];
    return _data;
  }
}
export var SkuType = /* @__PURE__ */ ((SkuType2) => {
  SkuType2["Amazon"] = "Amazon";
  SkuType2["Custom"] = "Custom";
  return SkuType2;
})(SkuType || {});
