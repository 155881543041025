import { html } from "lit-html";
import { setDataTransfer } from "GlobalShared/ui/dc-dropfield";
export function useSharedCustomDataTemplate() {
  const draggableItemTemplate = (label, info, id) => {
    const firefoxAgent = navigator.userAgent.indexOf("Firefox") > -1;
    return html`
			<div
				class="flex font-semibold w-64 shadow-md cursor-move justify-between border-brand-primary bg-white border border-solid rounded-xl px-4 py-2"
				id=${`draggable-${id}`}
				draggable="true"
				@dragstart=${(e) => {
      const draggedItem = {
        type: "TemplateDataSource",
        id,
        label,
        info
      };
      setDataTransfer(e, draggedItem);
      const element = document.getElementById(`draggable-${id}`);
      const dragImage = element.cloneNode(true);
      dragImage.id = "draggeimage";
      dragImage.style.display = "none";
      dragImage.style.position = "absolute";
      document.body.appendChild(dragImage);
      if (!firefoxAgent) {
        e.dataTransfer.setDragImage(dragImage, 0, 0);
      }
    }}
				@dragend=${() => {
      const dragImage = document.getElementById("draggeimage");
      if (dragImage) {
        dragImage.remove();
      }
    }}
				@drag=${(e) => {
      const dragImage = document.getElementById("draggeimage");
      if (dragImage && e.pageX && e.pageY) {
        dragImage.style.display = "block";
        dragImage.style.left = e.pageX + "px";
        dragImage.style.top = e.pageY + "px";
      }
    }}
			>
				<div class="truncate" title=${label}>${label}</div>
			</div>
		`;
  };
  return { draggableItemTemplate };
}
