var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DateConverter } from "./../../../../converters/DateConverter";
import { File } from "./File";
import { SubmissionProof } from "./SubmissionProof";
export class SubmissionPeriod {
  constructor(_data) {
    /**
     */
    __publicField(this, "ClientApprovalDate");
    /**
     */
    __publicField(this, "ClientApprovalDueDate");
    /**
     */
    __publicField(this, "ClientSource");
    /**
     */
    __publicField(this, "CompanyId");
    /**
     */
    __publicField(this, "CompanyName");
    /**
     */
    __publicField(this, "EFiling");
    /**
     */
    __publicField(this, "Errors");
    /**
     */
    __publicField(this, "EstablishmentCountryCode");
    /**
     */
    __publicField(this, "EstablishmentCountryType");
    /**
     */
    __publicField(this, "FrequencyType");
    /**
     */
    __publicField(this, "Id");
    /**
     */
    __publicField(this, "IgnoreWarnings");
    /**
     */
    __publicField(this, "IsSubmissionOnly");
    /**
     */
    __publicField(this, "PaymentCategory");
    /**
     */
    __publicField(this, "PaymentDueDate");
    /**
     */
    __publicField(this, "PaymentProof");
    /**
     */
    __publicField(this, "PaymentReference");
    /**
     */
    __publicField(this, "PaymentStatus");
    /**
     */
    __publicField(this, "PaymentUrl");
    /**
     */
    __publicField(this, "Period");
    /**
     */
    __publicField(this, "PeriodStartDate");
    /**
     */
    __publicField(this, "ReturnGenerationDate");
    /**
     */
    __publicField(this, "ReturnGenerationDueDate");
    /**
     */
    __publicField(this, "Revision");
    /**
     */
    __publicField(this, "ServiceCountryCode");
    /**
     */
    __publicField(this, "Status");
    /**
     */
    __publicField(this, "SubmissionDate");
    /**
     */
    __publicField(this, "SubmissionDueDate");
    /**
     */
    __publicField(this, "SubmissionProofs");
    /**
     */
    __publicField(this, "SubmittedAndPaidDate");
    /**
     */
    __publicField(this, "TaxReturnType");
    /**
     */
    __publicField(this, "Warnings");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof SubmissionPeriod
     */
    __publicField(this, "$type", "V1.Submission.Search.SubmissionPeriod");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    if (_data) {
      this.ClientApprovalDate = DateConverter.from(_data["ClientApprovalDate"]);
      this.ClientApprovalDueDate = DateConverter.from(_data["ClientApprovalDueDate"]);
      this.ClientSource = SubmissionPeriodClientSource[_data["ClientSource"]];
      this.CompanyId = _data["CompanyId"];
      this.CompanyName = _data["CompanyName"];
      this.EFiling = _data["EFiling"];
      if (_data["Errors"] && _data["Errors"].constructor === Array) {
        this.Errors = [];
        for (let item of _data["Errors"]) {
          this.Errors.push(item);
        }
      }
      this.EstablishmentCountryCode = _data["EstablishmentCountryCode"];
      this.EstablishmentCountryType = SubmissionPeriodEstablishmentCountryType[_data["EstablishmentCountryType"]];
      this.FrequencyType = SubmissionPeriodFrequencyType[_data["FrequencyType"]];
      this.Id = _data["Id"];
      this.IgnoreWarnings = _data["IgnoreWarnings"];
      this.IsSubmissionOnly = _data["IsSubmissionOnly"];
      this.PaymentCategory = SubmissionPeriodPaymentCategory[_data["PaymentCategory"]];
      this.PaymentDueDate = DateConverter.from(_data["PaymentDueDate"]);
      if (_data["PaymentProof"]) {
        const paymentProof_ = new File();
        this.PaymentProof = paymentProof_.init(_data["PaymentProof"]);
      }
      this.PaymentReference = _data["PaymentReference"];
      this.PaymentStatus = SubmissionPeriodPaymentStatus[_data["PaymentStatus"]];
      this.PaymentUrl = _data["PaymentUrl"];
      this.Period = _data["Period"];
      this.PeriodStartDate = DateConverter.from(_data["PeriodStartDate"]);
      this.ReturnGenerationDate = DateConverter.from(_data["ReturnGenerationDate"]);
      this.ReturnGenerationDueDate = DateConverter.from(_data["ReturnGenerationDueDate"]);
      this.Revision = _data["Revision"];
      this.ServiceCountryCode = _data["ServiceCountryCode"];
      this.Status = SubmissionPeriodStatus[_data["Status"]];
      this.SubmissionDate = DateConverter.from(_data["SubmissionDate"]);
      this.SubmissionDueDate = DateConverter.from(_data["SubmissionDueDate"]);
      if (_data["SubmissionProofs"] && _data["SubmissionProofs"].constructor === Array) {
        this.SubmissionProofs = [];
        for (let item of _data["SubmissionProofs"]) {
          const submissionProofs_ = new SubmissionProof();
          this.SubmissionProofs.push(submissionProofs_.init(item));
        }
      }
      this.SubmittedAndPaidDate = DateConverter.from(_data["SubmittedAndPaidDate"]);
      this.TaxReturnType = SubmissionPeriodTaxReturnType[_data["TaxReturnType"]];
      if (_data["Warnings"] && _data["Warnings"].constructor === Array) {
        this.Warnings = [];
        for (let item of _data["Warnings"]) {
          this.Warnings.push(item);
        }
      }
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    _data["ClientApprovalDate"] = DateConverter.toUtcDateString(this.ClientApprovalDate);
    _data["ClientApprovalDueDate"] = DateConverter.toUtcDateString(this.ClientApprovalDueDate);
    _data["ClientSource"] = SubmissionPeriodClientSource[this.ClientSource];
    _data["CompanyId"] = this.CompanyId;
    _data["CompanyName"] = this.CompanyName;
    _data["EFiling"] = this.EFiling;
    if (this.Errors && this.Errors.constructor === Array) {
      _data["Errors"] = [];
      for (let item of this.Errors) {
        _data["Errors"].push(item);
      }
    }
    _data["EstablishmentCountryCode"] = this.EstablishmentCountryCode;
    _data["EstablishmentCountryType"] = SubmissionPeriodEstablishmentCountryType[this.EstablishmentCountryType];
    _data["FrequencyType"] = SubmissionPeriodFrequencyType[this.FrequencyType];
    _data["Id"] = this.Id;
    _data["IgnoreWarnings"] = this.IgnoreWarnings;
    _data["IsSubmissionOnly"] = this.IsSubmissionOnly;
    _data["PaymentCategory"] = SubmissionPeriodPaymentCategory[this.PaymentCategory];
    _data["PaymentDueDate"] = DateConverter.toUtcDateString(this.PaymentDueDate);
    _data["PaymentProof"] = this.PaymentProof ? this.PaymentProof.toJSON() : void 0;
    _data["PaymentReference"] = this.PaymentReference;
    _data["PaymentStatus"] = SubmissionPeriodPaymentStatus[this.PaymentStatus];
    _data["PaymentUrl"] = this.PaymentUrl;
    _data["Period"] = this.Period;
    _data["PeriodStartDate"] = DateConverter.toUtcDateString(this.PeriodStartDate);
    _data["ReturnGenerationDate"] = DateConverter.toUtcDateString(this.ReturnGenerationDate);
    _data["ReturnGenerationDueDate"] = DateConverter.toUtcDateString(this.ReturnGenerationDueDate);
    _data["Revision"] = this.Revision;
    _data["ServiceCountryCode"] = this.ServiceCountryCode;
    _data["Status"] = SubmissionPeriodStatus[this.Status];
    _data["SubmissionDate"] = DateConverter.toUtcDateString(this.SubmissionDate);
    _data["SubmissionDueDate"] = DateConverter.toUtcDateString(this.SubmissionDueDate);
    if (this.SubmissionProofs && this.SubmissionProofs.constructor === Array) {
      _data["SubmissionProofs"] = [];
      for (let item of this.SubmissionProofs) {
        _data["SubmissionProofs"].push(item.toJSON());
      }
    }
    _data["SubmittedAndPaidDate"] = DateConverter.toUtcDateString(this.SubmittedAndPaidDate);
    _data["TaxReturnType"] = SubmissionPeriodTaxReturnType[this.TaxReturnType];
    if (this.Warnings && this.Warnings.constructor === Array) {
      _data["Warnings"] = [];
      for (let item of this.Warnings) {
        _data["Warnings"].push(item);
      }
    }
    return _data;
  }
}
export var SubmissionPeriodClientSource = /* @__PURE__ */ ((SubmissionPeriodClientSource2) => {
  SubmissionPeriodClientSource2["TaxDesk"] = "TaxDesk";
  SubmissionPeriodClientSource2["Geopost"] = "Geopost";
  SubmissionPeriodClientSource2["Partner"] = "Partner";
  SubmissionPeriodClientSource2["Hellotax"] = "Hellotax";
  return SubmissionPeriodClientSource2;
})(SubmissionPeriodClientSource || {});
export var SubmissionPeriodEstablishmentCountryType = /* @__PURE__ */ ((SubmissionPeriodEstablishmentCountryType2) => {
  SubmissionPeriodEstablishmentCountryType2["EU"] = "EU";
  SubmissionPeriodEstablishmentCountryType2["NonEU"] = "NonEU";
  SubmissionPeriodEstablishmentCountryType2["GB"] = "GB";
  return SubmissionPeriodEstablishmentCountryType2;
})(SubmissionPeriodEstablishmentCountryType || {});
export var SubmissionPeriodFrequencyType = /* @__PURE__ */ ((SubmissionPeriodFrequencyType2) => {
  SubmissionPeriodFrequencyType2["Monthly"] = "Monthly";
  SubmissionPeriodFrequencyType2["Quarterly"] = "Quarterly";
  SubmissionPeriodFrequencyType2["QuarterlyShifted1"] = "QuarterlyShifted1";
  SubmissionPeriodFrequencyType2["QuarterlyShifted2"] = "QuarterlyShifted2";
  SubmissionPeriodFrequencyType2["BiMonthly"] = "BiMonthly";
  SubmissionPeriodFrequencyType2["FourMonthly"] = "FourMonthly";
  SubmissionPeriodFrequencyType2["SixMonthly"] = "SixMonthly";
  SubmissionPeriodFrequencyType2["Yearly"] = "Yearly";
  return SubmissionPeriodFrequencyType2;
})(SubmissionPeriodFrequencyType || {});
export var SubmissionPeriodPaymentCategory = /* @__PURE__ */ ((SubmissionPeriodPaymentCategory2) => {
  SubmissionPeriodPaymentCategory2["Payable"] = "Payable";
  SubmissionPeriodPaymentCategory2["Reclaimable"] = "Reclaimable";
  SubmissionPeriodPaymentCategory2["Nil"] = "Nil";
  return SubmissionPeriodPaymentCategory2;
})(SubmissionPeriodPaymentCategory || {});
export var SubmissionPeriodPaymentStatus = /* @__PURE__ */ ((SubmissionPeriodPaymentStatus2) => {
  SubmissionPeriodPaymentStatus2["Due"] = "Due";
  SubmissionPeriodPaymentStatus2["Paid"] = "Paid";
  SubmissionPeriodPaymentStatus2["PaidByClient"] = "PaidByClient";
  SubmissionPeriodPaymentStatus2["NoPaymentNeeded"] = "NoPaymentNeeded";
  return SubmissionPeriodPaymentStatus2;
})(SubmissionPeriodPaymentStatus || {});
export var SubmissionPeriodStatus = /* @__PURE__ */ ((SubmissionPeriodStatus2) => {
  SubmissionPeriodStatus2["New"] = "New";
  SubmissionPeriodStatus2["DataUploaded"] = "DataUploaded";
  SubmissionPeriodStatus2["ErrorReturnGen"] = "ErrorReturnGen";
  SubmissionPeriodStatus2["ReturnAvailable"] = "ReturnAvailable";
  SubmissionPeriodStatus2["ApprovedByClient"] = "ApprovedByClient";
  SubmissionPeriodStatus2["RefusedByClient"] = "RefusedByClient";
  SubmissionPeriodStatus2["ErrorAtTA"] = "ErrorAtTA";
  SubmissionPeriodStatus2["SentToTA"] = "SentToTA";
  SubmissionPeriodStatus2["Submitted"] = "Submitted";
  SubmissionPeriodStatus2["Rejected"] = "Rejected";
  SubmissionPeriodStatus2["SubmittedByClient"] = "SubmittedByClient";
  SubmissionPeriodStatus2["NotToBeSubmitted"] = "NotToBeSubmitted";
  return SubmissionPeriodStatus2;
})(SubmissionPeriodStatus || {});
export var SubmissionPeriodTaxReturnType = /* @__PURE__ */ ((SubmissionPeriodTaxReturnType2) => {
  SubmissionPeriodTaxReturnType2["PrimaryVAT"] = "PrimaryVAT";
  SubmissionPeriodTaxReturnType2["SecondaryVAT"] = "SecondaryVAT";
  SubmissionPeriodTaxReturnType2["ESL"] = "ESL";
  SubmissionPeriodTaxReturnType2["VLS"] = "VLS";
  SubmissionPeriodTaxReturnType2["IOSS"] = "IOSS";
  SubmissionPeriodTaxReturnType2["Intrastat"] = "Intrastat";
  SubmissionPeriodTaxReturnType2["OSS"] = "OSS";
  SubmissionPeriodTaxReturnType2["LocalListing"] = "LocalListing";
  return SubmissionPeriodTaxReturnType2;
})(SubmissionPeriodTaxReturnType || {});
