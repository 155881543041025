var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import classNames from "classnames";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { mapValidationMessage } from "./ComplianceUploadModels";
import { DEFAULT_VAT_CONFIG, INITIAL_POLL_TIMEOUT_IN_MILLIS, isFinalizedStatusLike, MAX_POLL_TIMEOUT_IN_MILLIS } from "./ComplianceUploadsHelper";
import { ComplianceUploadType as DtoComplianceUploadType } from "AdminServices/APIs/self/models/V1/ComplianceUploads/ComplianceUpload";
import moment from "moment";
import { circleCheckedIcon, fileIcon, triangleExclamationIcon } from "GlobalShared/templates/commons";
import { extractFirstError, notifyError, notifyErrorWithWarning, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { navigateTo } from "GlobalShared/router";
import { uploadFileToAzureStorage } from "GlobalShared/helpers/sasHelper";
import { useAmazonUpload } from "./useAmazonUpload";
import { useIossUpload } from "./useIossUpload";
import { useSaftUpload } from "./useSaftUpload";
import { useVatOssUpload } from "./useVatOssUpload";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import { useCustomSourceTemplateUpload } from "./useCustomSourceTemplateUpload";
export const useComplianceUploadModal = (props) => {
  var _a;
  const getGenerationConfiguration = (companyId) => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const config = yield props.getGenerationConfiguration(companyId);
        setGenerationConfiguration(config);
      }),
      (error) => {
        if (error.statusCode === 404) {
          setGenerationConfiguration(void 0);
        } else {
          notifyError(error);
        }
      }
    );
  });
  const getCompanyDetail = (companyId) => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const companyDetail = yield props.getCompanyServices(companyId);
        setCompanyServices(companyDetail);
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const isDataProcessingJobInEndState = (job) => job.status === "Success" || job.status === "Error" || job.status === "ValidationError";
  const resetUploadModalState = () => {
    setCurrentUploadJobs(void 0);
    setCurrentJobUploadInProgress(void 0);
  };
  const hasVatOrOssRegistration = () => {
    var _a2, _b;
    return ((_a2 = companyServices == null ? void 0 : companyServices.VatRegistrations) == null ? void 0 : _a2.length) > 0 || ((_b = companyServices == null ? void 0 : companyServices.GlobalServices.OssService) == null ? void 0 : _b.RegistrationStartDate) !== void 0;
  };
  const isToggleStateChanged = (oldState, newState) => oldState !== newState;
  const hasAnyOngoingDataProcessingJob = () => currentUploadJobs == null ? void 0 : currentUploadJobs.some((job) => !isDataProcessingJobInEndState(job) && job.status !== "New");
  const showVatConfiguration = () => defaultCompanyConfiguration !== void 0 && hasVatOrOssRegistration();
  const hasOngoingDataProcessingJob = (_jobType, _sellerEntityId) => {
    return hasAnyOngoingDataProcessingJob();
  };
  const uploadFileToBlob = (file, dataProcessingJobCreateType, sellerEntityId) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        props.dispatchFullScreenLoading("inc");
        const createResult = yield props.createUploadJob(currentComplianceUpload.Id, file.name, dataProcessingJobCreateType, sellerEntityId);
        const indexOfQuestionMark = createResult.sasUri.indexOf("?") > -1 ? createResult.sasUri.indexOf("?") : createResult.sasUri.length;
        const blobUrl = createResult.sasUri.substring(0, indexOfQuestionMark);
        const sasToken = createResult.sasUri.substring(indexOfQuestionMark);
        yield uploadFileToAzureStorage(file, blobUrl, (_blobUrl) => __async(void 0, null, function* () {
          return sasToken;
        }));
        yield props.finishBlobUpload(createResult.jobId, createResult.correlationId);
        setCurrentJobUploadInProgress(createResult.jobId);
        yield loadDataProcessingJobs();
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      () => {
        props.dispatchFullScreenLoading("dec");
      }
    );
  });
  const linkGeneratorForLatestInvalidFiles = (dataProcessingJobId) => {
    return `${window.location.origin}/api/v1/DataProcessingJobs/${dataProcessingJobId}/File`;
  };
  const isDataProcessingJobOngoingForUploadType = (jobType, sellerEntityId) => {
    return currentUploadJobs == null ? void 0 : currentUploadJobs.some((job) => jobType === job.type && sellerEntityId === job.sellerEntityId && !isDataProcessingJobInEndState(job) && job.status !== "New");
  };
  const finalizeUpload = (complianceUpload, confirmNoSaft, acknowledgeIncompleteSkus) => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        props.dispatchFullScreenLoading("inc");
        //!!! this if is added just because of TDP-1578
        if ((defaultCompanyConfiguration == null ? void 0 : defaultCompanyConfiguration.amazonConfiguration) !== void 0) {
          const defaultConfig = __spreadProps(__spreadValues({}, defaultCompanyConfiguration), {
            amazonConfiguration: __spreadValues({}, defaultCompanyConfiguration == null ? void 0 : defaultCompanyConfiguration.amazonConfiguration)
          });
          setDefaultCompanyConfiguration(defaultConfig);
          setAutoSaveCompanyConfiguration(false);
          yield props.updateDefaultCompanyConfiguration(defaultConfig, currentComplianceUpload == null ? void 0 : currentComplianceUpload.ClientId);
        }
        yield props.finalizeComplianceUpload(complianceUpload, confirmNoSaft, acknowledgeIncompleteSkus);
        notifySuccess(i18next.t("Successfully done"));
      }),
      (error) => {
        const firstError = extractFirstError(error);
        if ((firstError == null ? void 0 : firstError.code) === "ComplianceUploadFileMissing") {
          notifyWarning(i18next.t("There is no file uploaded for the current revision. Please reupload the file."));
        } else {
          notifyErrorWithWarning(error);
        }
      },
      () => __async(void 0, null, function* () {
        props.dispatchFullScreenLoading("dec");
        setCloseUploadModalOnFinalize(true);
        yield props.loadComplianceUploads();
        const complianceUploadRes = yield props.getCompliance(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Id);
        setCurrentComplianceUpload(complianceUploadRes);
      })
    );
  };
  const isSaftComplianceUploadInvalid = (job) => currentComplianceUpload.Status === "SaftCompliance" && (job.type === "AmazonSaft" || job.type === "CustomSaft") && job.status !== "Success";
  const isVatComplianceUploadInvalid = (job) => currentComplianceUpload.Status === "New" && job.type === "Vat" && job.status !== "Success" && (defaultCompanyConfiguration == null ? void 0 : defaultCompanyConfiguration.useMpTemplate);
  const isOneFileComplianceUploadInvalid = (job) => currentComplianceUpload.Status === "New" && job.type === "CustomOneFile" && job.status !== "Success";
  const isCustomSaleFileComplianceUploadInvalid = (job) => currentComplianceUpload.Status === "New" && job.type === "CustomSaleFile" && job.status !== "Success";
  const isCustomPurchaseFileComplianceUploadInvalid = (job) => currentComplianceUpload.Status === "New" && job.type === "CustomPurchaseFile" && job.status !== "Success";
  const isAmazonComplianceUploadInvalid = (job) => {
    var _a2;
    return currentComplianceUpload.Status === "New" && job.type === "Amazon" && job.status !== "Success" && (defaultCompanyConfiguration == null ? void 0 : defaultCompanyConfiguration.useAmazonTemplate) && ((_a2 = defaultCompanyConfiguration == null ? void 0 : defaultCompanyConfiguration.amazonConfiguration) == null ? void 0 : _a2.sellerEntities.some((se) => job.sellerEntityId === se.id && se.isEnabled));
  };
  const isIossComplianceUploadInvalid = (job) => (currentComplianceUpload.Status === "New" || currentComplianceUpload.Status === "WaitingForFinalize") && job.type === "Ioss" && job.status !== "Success";
  const isFinalizeButtonEnabled = () => {
    var _a2, _b;
    if (currentUploadJobs == null ? void 0 : currentUploadJobs.some(
      (job) => isAmazonComplianceUploadInvalid(job) || isVatComplianceUploadInvalid(job) || isSaftComplianceUploadInvalid(job) || isIossComplianceUploadInvalid(job) || isOneFileComplianceUploadInvalid(job) || isCustomSaleFileComplianceUploadInvalid(job) || isCustomPurchaseFileComplianceUploadInvalid(job)
    )) {
      return false;
    }
    if ((currentComplianceUpload == null ? void 0 : currentComplianceUpload.Type) === "IOSS") {
      if (currentComplianceUpload.Status !== "WaitingForFinalize") {
        return false;
      }
      return ((_a2 = currentComplianceUpload.IossFile) == null ? void 0 : _a2.Reference) !== void 0;
    } else {
      if (defaultCompanyConfiguration === void 0) {
        return false;
      }
      if ((currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "New") {
        const isAllAmazonFilesUploaded = defaultCompanyConfiguration == null ? void 0 : defaultCompanyConfiguration.amazonConfiguration.sellerEntities.every(
          (se) => {
            var _a3;
            return !se.isEnabled || ((_a3 = currentComplianceUpload.AmazonFiles) == null ? void 0 : _a3.some((af) => af.SellerEntity.Id === se.id && af.Reference));
          }
        );
        const hasEnabledSellerEntity = defaultCompanyConfiguration == null ? void 0 : defaultCompanyConfiguration.amazonConfiguration.sellerEntities.some((se) => se.isEnabled);
        const isAmazonValid = !defaultCompanyConfiguration.useAmazonTemplate || hasEnabledSellerEntity && isAllAmazonFilesUploaded;
        const isMpFileUploaded = ((_b = currentComplianceUpload.MpTemplateFile) == null ? void 0 : _b.Reference) !== void 0;
        const isMpValid = !defaultCompanyConfiguration.useMpTemplate || isMpFileUploaded;
        const isCustomSourceOneFileUploaded = currentComplianceUpload.CustomOneFile !== void 0;
        const areCustomSourceSaleAndPurchaseFilesUploaded = currentComplianceUpload.CustomSaleFile !== void 0 && currentComplianceUpload.CustomPurchaseFile !== void 0;
        const isCustomSourceUploadValid = isCustomSourceOneFileUploaded || areCustomSourceSaleAndPurchaseFilesUploaded;
        return (generationConfiguration == null ? void 0 : generationConfiguration.IsCustomSource) ? isCustomSourceUploadValid : isAmazonValid && isMpValid;
      } else if ((currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "MasterDataSetup") {
        return hasAnyIncompleteSKUs === "No" && hasAnyIncompleteTaxCodes === "No";
      } else {
        return currentComplianceUpload.AmazonSaftFile !== void 0 || currentComplianceUpload.CustomSaftFile !== void 0;
      }
    }
  };
  const loadDataProcessingJobs = () => __async(void 0, null, function* () {
    if (currentComplianceUpload) {
      const result = yield props.searchUploadJobs(currentComplianceUpload.Id);
      setCurrentUploadJobs(result);
    }
  });
  const onSuppressUploadJobWarnings = (jobId) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        props.dispatchFullScreenLoading("inc");
        setCurrentJobDetails(void 0);
        yield props.suppressUploadJobWarnings(jobId);
        yield loadDataProcessingJobs();
        yield props.loadComplianceUploads();
        const complianceUpload = yield props.getCompliance(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Id);
        setCurrentComplianceUpload(complianceUpload);
        setUploadModalMode("Opened");
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      () => {
        props.dispatchFullScreenLoading("dec");
      }
    );
  });
  const isPeriodOverdue = (cu) => {
    return false;
    if (!cu) {
      return false;
    }
    const period = moment(cu == null ? void 0 : cu.Period);
    return cu.Type === DtoComplianceUploadType.IOSS && !cu.IgnoreOverdue && period.add(2, "M").add(-6, "d") < moment();
  };
  const handleUploadModalClose = () => {
    if (uploadModalState === "ShowValidationError" || uploadModalState === "ShowSellerEntityEdit" || uploadModalState === "ShowAddNewSellerEntityFromModal") {
      setUploadModalMode("Opened");
    } else if (uploadModalState !== "Closed") {
      setUploadModalMode("Closed");
      return;
    }
    setCurrentJobDetails(void 0);
  };
  const deleteJobs = (type) => __async(void 0, null, function* () {
    if (currentUploadJobs !== void 0) {
      const jobs = currentUploadJobs.filter((job) => job.type === type);
      if (jobs !== void 0 && jobs.length > 0) {
        jobs.map((job) => __async(void 0, null, function* () {
          return props.deleteJob(job.id);
        }));
        setCurrentUploadJobs(currentUploadJobs.filter((currentUploadJob) => currentUploadJob.type !== type));
      }
    }
  });
  const getComplianceUploadModalStepTitle = () => {
    if (props.isTransactionalModalReadonlyMode) {
      return "";
    }
    if ((currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "New" || (currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "WaitingForFinalize") {
      return i18next.t("Transactions");
    } else if ((currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "MasterDataSetup") {
      return i18next.t("Master data");
    } else if ((currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "SaftCompliance") {
      return i18next.t("SAF-T");
    } else {
      return i18next.t("Finalized");
    }
  };
  const checkIncompleteSkus = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const result = yield props.hasAnyIncompleteSKUs(currentComplianceUpload.ClientId, currentComplianceUpload.Id);
        setHasAnyIncompleteSKUs(result ? "Yes" : "No");
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const checkIncompleteTaxCodes = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const result = yield props.hasAnyIncompleteTaxCodes(currentComplianceUpload.CountryCode, currentComplianceUpload.ClientId);
        setHasAnyIncompleteTaxCodes(result ? "Yes" : "No");
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const getDefaultCompanyConfiguration = (companyId) => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const companyConfig = yield props.getDefaultCompanyConfiguration(companyId);
        setDefaultCompanyConfiguration(__spreadProps(__spreadValues({}, companyConfig), {
          amazonConfiguration: __spreadValues({}, companyConfig.amazonConfiguration)
        }));
        setAutoSaveCompanyConfiguration(false);
      }),
      (error) => {
        if (error.statusCode === 404) {
          setDefaultCompanyConfiguration(DEFAULT_VAT_CONFIG);
        } else {
          notifyError(error);
        }
      }
    );
  });
  const isSaftUploadStep = () => __async(void 0, null, function* () {
    var _a2;
    return ((_a2 = currentComplianceUpload == null ? void 0 : currentComplianceUpload.AmazonFiles) == null ? void 0 : _a2.length) > 0 || (currentComplianceUpload == null ? void 0 : currentComplianceUpload.CustomSaleFile) !== void 0 && (currentComplianceUpload == null ? void 0 : currentComplianceUpload.CustomPurchaseFile) !== void 0;
  });
  const linkGenerator = (complianceUploadId, reference) => `${window.location.origin}/api/v1/ComplianceUploads/${complianceUploadId}/Files/${reference}`;
  const [uploadModalState, setUploadModalMode] = useState("Closed");
  const [currentComplianceUpload, setCurrentComplianceUpload] = useState(void 0);
  const [currentJobDetails, setCurrentJobDetails] = useState(void 0);
  const [defaultCompanyConfiguration, setDefaultCompanyConfiguration] = useState(void 0);
  const [currentUploadJobs, setCurrentUploadJobs] = useState(void 0);
  const [closeUploadModalOnFinalize, setCloseUploadModalOnFinalize] = useState(false);
  const [autoSaveCompanyConfiguration, setAutoSaveCompanyConfiguration] = useState(true);
  const [currentJobUploadInProgress, setCurrentJobUploadInProgress] = useState(void 0);
  const [hasAnyIncompleteSKUs, setHasAnyIncompleteSKUs] = useState(void 0);
  const [hasAnyIncompleteTaxCodes, setHasAnyIncompleteTaxCodes] = useState(void 0);
  const [companyServices, setCompanyServices] = useState(void 0);
  const [currentPollTimeoutInMillis, setCurrentPollTimeoutInMillis] = useState(INITIAL_POLL_TIMEOUT_IN_MILLIS);
  const [generationConfiguration, setGenerationConfiguration] = useState(void 0);
  useEffect(resetUploadModalState, []);
  useEffect(() => __async(void 0, null, function* () {
    if (defaultCompanyConfiguration !== void 0) {
      if (autoSaveCompanyConfiguration) {
        yield props.updateDefaultCompanyConfiguration(defaultCompanyConfiguration, currentComplianceUpload == null ? void 0 : currentComplianceUpload.ClientId);
        yield getDefaultCompanyConfiguration(currentComplianceUpload == null ? void 0 : currentComplianceUpload.ClientId);
      } else {
        setAutoSaveCompanyConfiguration(true);
      }
    }
  }), [defaultCompanyConfiguration]);
  useEffect(() => __async(void 0, null, function* () {
    try {
      props.dispatchFullScreenLoading("inc");
      if (props.isAdmin && currentComplianceUpload !== void 0) {
        if (currentComplianceUpload.Type !== "IOSS") {
          yield getDefaultCompanyConfiguration(currentComplianceUpload.ClientId);
        }
        yield getCompanyDetail(currentComplianceUpload.ClientId);
      }
      if (currentComplianceUpload !== void 0) {
        if (currentComplianceUpload.Type === "VAT" && currentComplianceUpload.Status === "MasterDataSetup") {
          setHasAnyIncompleteSKUs("DontKnow");
          setHasAnyIncompleteTaxCodes("DontKnow");
          yield checkIncompleteSkus();
          yield checkIncompleteTaxCodes();
        }
        setCurrentUploadJobs(void 0);
        yield loadDataProcessingJobs();
      }
      if ((currentComplianceUpload === void 0 || currentComplianceUpload.Status === "DataUploadFinalized") && closeUploadModalOnFinalize && uploadModalState === "Opened") {
        setCloseUploadModalOnFinalize(false);
        setUploadModalMode("Closed");
      }
    } catch (error) {
      notifyError(error);
    } finally {
      props.dispatchFullScreenLoading("dec");
    }
  }), [currentComplianceUpload == null ? void 0 : currentComplianceUpload.Id, currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status, uploadModalState]);
  useEffect(() => __async(void 0, null, function* () {
    var _a2;
    if (currentUploadJobs !== void 0) {
      if (currentUploadJobs.some((job) => job.status === "Pending" || job.status === "UploadCompleted")) {
        if (currentPollTimeoutInMillis < MAX_POLL_TIMEOUT_IN_MILLIS) {
          const timeoutHandler = window.setTimeout(() => {
            loadDataProcessingJobs();
          }, currentPollTimeoutInMillis);
          setCurrentPollTimeoutInMillis(currentPollTimeoutInMillis * 2);
          return () => {
            window.clearTimeout(timeoutHandler);
          };
        }
      } else if (currentPollTimeoutInMillis > INITIAL_POLL_TIMEOUT_IN_MILLIS) {
        setCurrentPollTimeoutInMillis(INITIAL_POLL_TIMEOUT_IN_MILLIS);
        yield props.loadComplianceUploads();
        if (((_a2 = currentUploadJobs.find((job) => job.id === currentJobUploadInProgress)) == null ? void 0 : _a2.status) === "Success") {
          const complianceUpload = yield props.getCompliance(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Id);
          setCurrentComplianceUpload(complianceUpload);
          notifySuccess(i18next.t("File successfully uploaded"));
          setCurrentJobUploadInProgress(void 0);
        }
      }
    }
    return void 0;
  }), [currentUploadJobs]);
  useEffect(() => __async(void 0, null, function* () {
    if (!props.isAdmin) {
      yield getDefaultCompanyConfiguration();
      yield getGenerationConfiguration();
      yield getCompanyDetail();
    }
  }), []);
  useEffect(() => __async(void 0, null, function* () {
    if (currentComplianceUpload !== void 0 && props.isAdmin) {
      yield getGenerationConfiguration(currentComplianceUpload == null ? void 0 : currentComplianceUpload.ClientId);
    }
  }), [currentComplianceUpload]);
  const htmlFileUploadStatus = (jobType, isErrorFileSameAsValidFile, file, isFileSuccessFullyUploaded, sellerEntityId, isActiveJobStatusValidiationError) => {
    var _a2, _b;
    const currentUploadJob = currentUploadJobs == null ? void 0 : currentUploadJobs.find((job) => job.type === jobType);
    const htmlValidationError = (isActiveJobStatusValidiationError === void 0 || isActiveJobStatusValidiationError) && !isDataProcessingJobOngoingForUploadType(jobType, sellerEntityId) ? htmlFileValidationError(currentUploadJob) : "";
    const htmlSuccessFullyUploadedFile = (file == null ? void 0 : file.Reference) && !isErrorFileSameAsValidFile && isFileSuccessFullyUploaded ? html`
						<div class="flex">
							<a class="text-sm hover:underline cursor-pointer" title=${(_a2 = file == null ? void 0 : file.Name) != null ? _a2 : ""} download=${file == null ? void 0 : file.Name} href=${linkGenerator(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Id, file == null ? void 0 : file.Reference)}>
								<div class="mr-2">${fileIcon(file == null ? void 0 : file.Name)}</div>
							</a>
							<div class="grid grid-cols-2 items-end gap-4">
								<a
									class="col-span-1 text-sm hover:underline cursor-pointer"
									title=${(_b = file == null ? void 0 : file.Name) != null ? _b : ""}
									download=${file == null ? void 0 : file.Name}
									href=${linkGenerator(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Id, file == null ? void 0 : file.Reference)}
									><div class="truncate">${file == null ? void 0 : file.Name}</div></a
								>
								${circleCheckedIcon(19, 19)}
							</div>
						</div>
				  ` : "";
    return html`
			<div class=${classNames("flex flex-col gap-4 mt-4", { "mb-4": (file == null ? void 0 : file.Reference) && !isErrorFileSameAsValidFile && isFileSuccessFullyUploaded })}>
				${htmlSuccessFullyUploadedFile} ${htmlValidationError}
			</div>
		`;
  };
  const amazonUpload = useAmazonUpload({
    uploadModalState,
    currentComplianceUpload,
    defaultCompanyConfiguration,
    isAdmin: props.isAdmin,
    currentUploadJobs,
    loadComplianceUploads: props.loadComplianceUploads,
    getCompliance: props.getCompliance,
    removeUploadedAmazonTransactions: props.removeUploadedAmazonTransactions,
    deleteJob: props.deleteJob,
    confirmModal: props.confirmModal,
    dispatchFullScreenLoading: props.dispatchFullScreenLoading,
    setUploadModalMode,
    setDefaultCompanyConfiguration,
    setCurrentComplianceUpload,
    deleteJobs,
    hasOngoingDataProcessingJob,
    isDataProcessingJobOngoingForUploadType,
    uploadFileToBlob,
    htmlFileUploadStatus,
    hasAnyOngoingDataProcessingJob,
    linkGenerator,
    isToggleStateChanged
  });
  const iossUpload = useIossUpload({
    currentComplianceUpload,
    currentUploadJobs,
    uploadFileToBlob,
    htmlFileUploadStatus,
    hasAnyOngoingDataProcessingJob,
    isDataProcessingJobOngoingForUploadType,
    linkGenerator,
    hasOngoingDataProcessingJob
  });
  const saftUpload = useSaftUpload({
    uploadEntityType: ((_a = currentComplianceUpload == null ? void 0 : currentComplianceUpload.AmazonFiles) == null ? void 0 : _a.length) > 0 ? "AmazonSaft" : "CustomSaft",
    file: (currentComplianceUpload == null ? void 0 : currentComplianceUpload.AmazonSaftFile) ? currentComplianceUpload == null ? void 0 : currentComplianceUpload.AmazonSaftFile : currentComplianceUpload == null ? void 0 : currentComplianceUpload.CustomSaftFile,
    isAdmin: props.isAdmin,
    currentComplianceUpload,
    currentUploadJobs,
    uploadFileToBlob,
    htmlFileUploadStatus,
    isDataProcessingJobOngoingForUploadType,
    linkGenerator,
    hasOngoingDataProcessingJob,
    dispatchFullScreenLoading: props.dispatchFullScreenLoading,
    generateSaftTemplate: props.generateSaftTemplate,
    isFinalizeButtonEnabled,
    setUploadModalMode,
    finalizeUpload,
    getRouteByKey: props.getRouteByKey
  });
  const vatOssUpload = useVatOssUpload({
    uploadModalState,
    currentComplianceUpload,
    defaultCompanyConfiguration,
    isAdmin: props.isAdmin,
    currentUploadJobs,
    loadComplianceUploads: props.loadComplianceUploads,
    getCompliance: props.getCompliance,
    deleteJob: props.deleteJob,
    confirmModal: props.confirmModal,
    dispatchFullScreenLoading: props.dispatchFullScreenLoading,
    setDefaultCompanyConfiguration,
    setCurrentComplianceUpload,
    deleteJobs,
    hasOngoingDataProcessingJob,
    isDataProcessingJobOngoingForUploadType,
    uploadFileToBlob,
    htmlFileUploadStatus,
    linkGenerator,
    isToggleStateChanged,
    removeUploadedVatTransactions: props.removeUploadedVatTransactions
  });
  const customSourceTemplateUpload = useCustomSourceTemplateUpload({
    currentComplianceUpload,
    currentUploadJobs,
    generationConfiguration,
    uploadFileToBlob,
    htmlFileUploadStatus,
    hasAnyOngoingDataProcessingJob,
    isDataProcessingJobOngoingForUploadType
  });
  const htmlUploadModalHeader = () => {
    if (uploadModalState === "ShowSellerEntityEdit") {
      return html`${i18next.t("Edit Seller Account")}`;
    }
    if (uploadModalState === "ShowAddNewSellerEntityFromModal" || uploadModalState === "ShowAddNewSellerEntity") {
      return html`${i18next.t("Add New Seller Account")}`;
    }
    return html`
			<div>
				${i18next.t("Data Upload")} ${props.isAdmin ? `| ${currentComplianceUpload == null ? void 0 : currentComplianceUpload.ClientName} (${currentComplianceUpload == null ? void 0 : currentComplianceUpload.ClientId}) ` : ""}| ${currentComplianceUpload == null ? void 0 : currentComplianceUpload.Period} |
				${getComplianceUploadModalStepTitle()}
			</div>
		`;
  };
  const htmlMasterDataStep = () => html`
			<div>
				<div>
					${hasAnyIncompleteSKUs === "Yes" ? i18next.t(
    "master-data-is-incomplete",
    "Master data is incomplete, some SKUs don't have price. Please setup or confirm ignoring setting up. If you do not add these missing prices, we will not be able to include the value of movements of own goods in the VAT returns and the ESL reports, which can trigger tax audits or lead to penalties, VIES deactivation or deregistration. You are solely responsible for the consequences. We hereby ask you to complete all missing values before proceeding."
  ) : ""}
					${hasAnyIncompleteTaxCodes === "Yes" ? i18next.t("incomplete-tax-codes") : ""}
					${hasAnyIncompleteSKUs === "No" && hasAnyIncompleteTaxCodes === "No" ? i18next.t("Master data is complete. Please submit data.") : ""}
					${hasAnyIncompleteSKUs === "DontKnow" || hasAnyIncompleteTaxCodes === "DontKnow" ? `${i18next.t("Loading")}...` : ""}
				</div>
				<div>
					<div class="mt-8 flex space-x-4">
						${SecondaryButton({ class: "max-w-fit", text: i18next.t("Cancel"), onClick: () => __async(void 0, null, function* () {
    return setUploadModalMode("Closed");
  }) })}
						${hasAnyIncompleteTaxCodes === "Yes" && !props.isAdmin ? PrimaryButton({
    class: "max-w-fit",
    text: i18next.t("Open Tax Code Settings"),
    onClick: () => __async(void 0, null, function* () {
      return navigateTo(props.getRouteByKey("admin/master-data/data-template").concat("?step=2"));
    })
  }) : ""}
						${hasAnyIncompleteSKUs === "Yes" && !props.isAdmin ? PrimaryButton({
    class: "max-w-fit",
    text: i18next.t("Go to Master Data/Amazon SKUs"),
    onClick: () => __async(void 0, null, function* () {
      return navigateTo(props.getRouteByKey("admin/master-data/amazon-skus"));
    })
  }) : ""}
						${hasAnyIncompleteSKUs === "Yes" ? PrimaryButton({
    class: "max-w-fit",
    text: i18next.t("Confirm without Master Data setup"),
    disabled: isFinalizeButtonEnabled(),
    onClick: () => __async(void 0, null, function* () {
      return finalizeUpload(currentComplianceUpload, void 0, true);
    })
  }) : ""}
						${hasAnyIncompleteSKUs === "No" ? PrimaryButton({
    class: "max-w-fit",
    text: i18next.t("Confirm and Generate Return"),
    disabled: !isFinalizeButtonEnabled(),
    onClick: () => __async(void 0, null, function* () {
      return finalizeUpload(currentComplianceUpload);
    })
  }) : ""}
					</div>
				</div>
			</div>
		`;
  const htmlCurrentStatusChange = () => !props.isTransactionalModalReadonlyMode && !isPeriodOverdue(currentComplianceUpload) ? html`${(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "New" || (currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "WaitingForFinalize" ? htmlTransactionUploadStep("Default") : ""}
			  ${(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "SaftCompliance" ? html`${isSaftUploadStep() ? saftUpload.htmlSaftStep() : saftUpload.htmlSaftConfirmStep()}` : ""}
			  ${(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) === "MasterDataSetup" ? htmlMasterDataStep() : ""} ${isFinalizedStatusLike(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Status) ? html`${i18next.t("Finalized")}` : ""}` : "";
  const htmlAvailableTemplates = (mode) => {
    var _a2, _b, _c, _d;
    const companyConfiguration = mode === "Readonly" ? currentComplianceUpload.CompanyConfigSnapshot : defaultCompanyConfiguration;
    return html`
			<div class="mb-4">
				<div class=${classNames({ hidden: !((_a2 = currentComplianceUpload == null ? void 0 : currentComplianceUpload.AvailableTemplates) == null ? void 0 : _a2.includes("Amazon")) && !((_b = currentComplianceUpload == null ? void 0 : currentComplianceUpload.AvailableTemplates) == null ? void 0 : _b.includes("Mp")) })}>
					<div class="font-bold text-xl">${i18next.t("VAT Compliance Settings")}</div>
				</div>
				${((_c = currentComplianceUpload == null ? void 0 : currentComplianceUpload.AvailableTemplates) == null ? void 0 : _c.includes("Mp")) && showVatConfiguration() ? vatOssUpload.htmlVatOssUpload(mode, companyConfiguration) : ""}
				${((_d = currentComplianceUpload == null ? void 0 : currentComplianceUpload.AvailableTemplates) == null ? void 0 : _d.includes("Amazon")) && showVatConfiguration() ? amazonUpload.htmlAmazonUpload(mode, companyConfiguration) : ""}
				${(generationConfiguration == null ? void 0 : generationConfiguration.IsCustomSource) ? customSourceTemplateUpload == null ? void 0 : customSourceTemplateUpload.htmlCustomSourceUpload(mode) : ""}
			</div>
		`;
  };
  const htmlViewValidationErrorDetails = (job) => {
    var _a2;
    return ((_a2 = job.latestUploadedFile) == null ? void 0 : _a2.Reference) ? html`
					<div
						class="text-sm hover:underline cursor-pointer text-red-500 max-w-fit"
						@click=${() => {
      setCurrentJobDetails(job);
      setUploadModalMode("ShowValidationError");
    }}
					>
						${i18next.t("Validation error, view details")}
					</div>
			  ` : "";
  };
  const htmlFileValidationError = (job) => {
    var _a2, _b, _c, _d, _e, _f, _g, _h, _i;
    return ((_a2 = job == null ? void 0 : job.latestUploadedFile) == null ? void 0 : _a2.Reference) ? html`
					<div class="flex flex-col">
						<div class="flex">
							<a
								class="col-span-1 text-sm hover:underline cursor-pointer"
								title=${(_c = (_b = job.latestUploadedFile) == null ? void 0 : _b.Name) != null ? _c : ""}
								download=${(_d = job.latestUploadedFile) == null ? void 0 : _d.Name}
								href=${linkGeneratorForLatestInvalidFiles(job.id)}
							>
								<div class="mr-2">${fileIcon((_e = job.latestUploadedFile) == null ? void 0 : _e.Name)}</div>
							</a>
							<div class="grid grid-cols-2 items-end gap-4">
								<a
									class="col-span-1 text-sm hover:underline cursor-pointer"
									title=${(_g = (_f = job.latestUploadedFile) == null ? void 0 : _f.Name) != null ? _g : ""}
									download=${(_h = job.latestUploadedFile) == null ? void 0 : _h.Name}
									href=${linkGeneratorForLatestInvalidFiles(job.id)}
								>
									<div class="truncate">${(_i = job.latestUploadedFile) == null ? void 0 : _i.Name}</div>
								</a>
								<div>${triangleExclamationIcon(19, 19)}</div>
							</div>
						</div>
						<div class="ml-8">${htmlViewValidationErrorDetails(job)}</div>
					</div>
			  ` : "";
  };
  const htmlTransactionUploadStep = (mode) => html`
			<div class="w-full h-full p-4">
				${(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Type) === "IOSS" ? iossUpload.htmlIossUpload(mode) : ""} ${(currentComplianceUpload == null ? void 0 : currentComplianceUpload.Type) === "VAT" ? htmlAvailableTemplates(mode) : ""}
				<div>
					<div class="mt-24 flex flex-wrap gap-4">
						${SecondaryButton({ class: "max-w-fit", text: i18next.t("Cancel"), onClick: () => __async(void 0, null, function* () {
    return setUploadModalMode("Closed");
  }) })}
						${mode === "Default" ? PrimaryButton({
    class: "max-w-fit",
    text: i18next.t("Confirm and Generate Return"),
    disabled: !isFinalizeButtonEnabled(),
    onClick: () => __async(void 0, null, function* () {
      return finalizeUpload(currentComplianceUpload);
    })
  }) : ""}
					</div>
				</div>
			</div>
		`;
  const htmlDataProcessingJobResult = (job) => {
    var _a2, _b, _c, _d, _e, _f;
    return html`
			<div>
				<div
					class="text-sm hover:underline cursor-pointer mb-4"
					@click=${() => {
      setCurrentJobDetails(void 0);
      setUploadModalMode("Opened");
    }}
				>
					${i18next.t("Close validation details")}
				</div>
				<div class="font-bold text-lg mt-4 mb-1">${i18next.t("Validation details")}</div>
				${((_a2 = job.validationResult) == null ? void 0 : _a2.numberOfTotalLinesWithError) > 0 ? html`<div class="my-2 text-red-400 font-semibold">
							${i18next.t("total-number-of-incorrect-lines", "Total number of incorrect lines: {{num}}", {
      num: job.validationResult.numberOfTotalLinesWithError
    })}
					  </div>` : ""}
				${job.status === "Error" ? html`<div class="text-red-400 mt-2">${i18next.t("Upload Failed")}</div>` : ""}
				${((_c = (_b = job.validationResult) == null ? void 0 : _b.validationMessages) == null ? void 0 : _c.length) > 0 ? html`
							<ul class="list-disc ml-4 mb-4">
								${(_e = (_d = job.validationResult) == null ? void 0 : _d.validationMessages) == null ? void 0 : _e.map((msg) => html`<li class="text-red-400">${mapValidationMessage(msg, job.type)}</li>`)}
							</ul>
					  ` : ""}
				${((_f = job.validationResult) == null ? void 0 : _f.validationMessages.every((vm) => vm.isWarning)) ? html`<div class="mt-4">${PrimaryButton({ class: "max-w-fit", text: i18next.t("Ignore and upload"), onClick: () => __async(void 0, null, function* () {
      return onSuppressUploadJobWarnings(job.id);
    }) })}</div>` : ""}
			</div>
		`;
  };
  const htmlPeriodOverdue = () => isPeriodOverdue(currentComplianceUpload) ? html`
					<div>
						${i18next.t(
    "disabled_ioss_upload_for_this_month",
    "You did not upload transaction data for this month. As it is not possible to submit IOSS returns retrospectively, the upload is not possible anymore. If you had sales in this month that must be reported via IOSS, you must add these transactions to the source data of the current IOSS return."
  )}
					</div>
			  ` : "";
  const htmlUploadModalContent = () => {
    if ((uploadModalState === "ShowAddNewSellerEntityFromModal" || uploadModalState === "ShowSellerEntityEdit" || uploadModalState === "ShowAddNewSellerEntity") && amazonUpload.currentSellerEntity) {
      return amazonUpload.htmlSellerEntityModalContent();
    }
    return currentJobDetails ? html`<div>${htmlDataProcessingJobResult(currentJobDetails)}</div>` : html`
					<div>
						${htmlPeriodOverdue()}
						${isPeriodOverdue(currentComplianceUpload) ? html`
									<div>
										${i18next.t(
      "disabled_ioss_upload_for_this_month",
      "You did not upload transaction data for this month. As it is not possible to submit IOSS returns retrospectively, the upload is not possible anymore. If you had sales in this month that must be reported via IOSS, you must add these transactions to the source data of the current IOSS return."
    )}
									</div>
							  ` : props.isTransactionalModalReadonlyMode ? htmlTransactionUploadStep("Readonly") : htmlCurrentStatusChange()}
					</div>
			  `;
  };
  const htmlTemplate = () => html`
		<dc-modal
			.visible=${uploadModalState !== "Closed"}
			@close=${handleUploadModalClose}
			.header=${htmlUploadModalHeader()}
			.content=${htmlUploadModalContent()}
			.customClass=${classNames("compliance-upload-modal", {
    "ioss-compliance": (currentComplianceUpload == null ? void 0 : currentComplianceUpload.Type) === "IOSS"
  })}
		>
		</dc-modal>
	`;
  return {
    companyServices,
    generationConfiguration,
    htmlTemplate,
    setCurrentComplianceUpload,
    setUploadModalMode
  };
};
