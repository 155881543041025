var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { html, svg, TemplateResult } from "lit-html";
import { useState } from "haunted";
import { alreadySignedUp, currentLocale, getLocalizedUrl, getRouteByKey } from "../globals";
import { addHandler, navigateTo, removeOptionals } from "GlobalShared/router";
import { useClient, useEffect, useLoadingReducer, useMemo } from "GlobalShared/haunted/CustomHooks";
import i18next from "i18next";
import { Companies as CompaniesClient, Company as CompanyClient, GenerationConfiguration as GenerationConfigurationClient } from "WebServices/APIs/self/clients";
import { ChangeCompany } from "WebServices/APIs/self/models/V1/Companies";
import { notifyError, notifyErrorWithWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { useNotification } from "./notification/notification";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { useBatchReturnUploadModal } from "./useBatchReturnUploadModal";
import classNames from "classnames";
import { OssComplianceSubscription, VatComplianceSubscription } from "WebServices/APIs/self/models/V1/CompanyInfo/Subscriptions";
import { DEFAULT_MAIN_MENU, MASTER_DATA_DATA_TEMPLATE_SUB_MENU, MENU_LABELS } from "./HeaderHelper";
import { useKpmgMode } from "GlobalShared/custom-hooks/SharedCustomHooks";
const isCurrentMenu = (key) => window.location.pathname.startsWith(removeOptionals(getRouteByKey(key)));
const startsWith = (pathPart) => window.location.pathname.substring("/gb/en/".length).startsWith(pathPart);
export function Header() {
  const companyClient = useClient(CompanyClient);
  const getCompanyDetail = (locale) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const companyDetail = yield companyClient.getCompanyDetail({
          locale
        });
        return companyDetail.data;
      }),
      (error) => {
        notifyError(error);
      },
      void 0
    );
  });
  const onToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const addNewCompany = () => {
    window.location.href = getLocalizedUrl("/admin") + "?newcompany=true";
  };
  const addMasterDataSubMenuItem = (subMenuItem) => setMainMenus(
    mainMenus.map(
      (m) => m.label === MENU_LABELS.MASTER_DATA ? __spreadProps(__spreadValues({}, m), {
        subMenus: [...m.subMenus, subMenuItem]
      }) : m
    )
  );
  const removeMasterDataSubMenu = (subMenuItem) => setMainMenus(
    mainMenus.map(
      (m) => m.label === MENU_LABELS.MASTER_DATA ? __spreadProps(__spreadValues({}, m), {
        subMenus: m.subMenus.filter((sm) => sm.label !== subMenuItem.label)
      }) : m
    )
  );
  const isCustomSourceDataTemplateMenuAlreadyAdded = () => {
    var _a, _b;
    return (_b = (_a = mainMenus.find((m) => m.label === MENU_LABELS.MASTER_DATA)) == null ? void 0 : _a.subMenus) == null ? void 0 : _b.find((sm) => sm.label === MASTER_DATA_DATA_TEMPLATE_SUB_MENU.label);
  };
  const handleCompanyChange = (e) => __async(this, null, function* () {
    const companyId = parseInt(e.detail.selectedValue);
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield companiesClient.changeCompany({ body: new ChangeCompany({ CompanyId: companyId }) });
        const isNewCompanyCustomSource = (yield generationConfigurationClient.get()).data.IsCustomSource;
        if (isCustomSource && !isNewCompanyCustomSource) {
          window.location.href = getLocalizedUrl("/admin/services");
          return;
        }
        if (window.location.href.includes("admin/orders")) {
          window.location.href = getLocalizedUrl("/admin/orders");
          return;
        }
        window.location.reload();
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      void 0,
      loader
    );
  });
  const [mainMenus, setMainMenus] = useState(DEFAULT_MAIN_MENU);
  const [menuOpen, setMenuOpen] = useState(false);
  const [hasSubmissionOnlySubscription, setHasSubmissionOnlySubscription] = useState(false);
  const [isCustomSource, setIsCustomSource] = useState(false);
  const [, setCurrentPathname] = useState(window.location.pathname);
  const currentCompanyId = useMemo(() => {
    var _a;
    return parseInt((_a = ClientContext.CompanyInformation) == null ? void 0 : _a.CompanyId);
  }, []);
  const currentCompanies = useMemo(() => {
    var _a, _b;
    return (_b = (_a = ClientContext.CompanyInformation) == null ? void 0 : _a.Companies.map((c) => ({ id: parseInt(c.Id), name: c.Name }))) != null ? _b : [];
  }, []);
  const companiesClient = useClient(CompaniesClient);
  const generationConfigurationClient = useClient(GenerationConfigurationClient);
  const notificationHook = useNotification();
  const batchReturnUploadModal = useBatchReturnUploadModal();
  const isKpmgMode = useKpmgMode();
  const [loading, dispatchLoading] = useLoadingReducer();
  const loader = {
    start: () => dispatchLoading("inc"),
    stop: () => dispatchLoading("dec")
  };
  const init = () => {
    const adminHomePath = getRouteByKey("admin");
    const guidesAndTutorialsPath = getRouteByKey("guides-and-tutorials");
    const faqsPath = getRouteByKey("faqs");
    const contactPath = getRouteByKey("contact");
    const cesopHomePath = getRouteByKey("admin/cesop/periods");
    const staticPaths = [guidesAndTutorialsPath, faqsPath, contactPath];
    if (ClientContext.IsPspUser && ![cesopHomePath, ...staticPaths].includes(window.location.pathname)) {
      navigateTo(cesopHomePath);
    }
    if (!ClientContext.IsPspUser && !alreadySignedUp() && ![adminHomePath, ...staticPaths].includes(window.location.pathname)) {
      console.log("reroute");
      navigateTo(adminHomePath);
    }
    return addHandler("webheader", (location) => {
      setCurrentPathname(location.pathname);
    });
  };
  useEffect(init, []);
  useEffect(() => __async(this, null, function* () {
    var _a, _b;
    if (!ClientContext.IsPspUser && ((_a = ClientContext.CompanyInformation) == null ? void 0 : _a.Companies.length) > 0) {
      const companyDetailResult = yield getCompanyDetail(currentLocale);
      setHasSubmissionOnlySubscription((_b = companyDetailResult == null ? void 0 : companyDetailResult.Subscriptions) == null ? void 0 : _b.some((s) => (s instanceof VatComplianceSubscription || s instanceof OssComplianceSubscription) && s.IsSubmissionOnly));
    }
  }), [currentCompanyId]);
  useEffect(() => __async(this, null, function* () {
    var _a;
    if (((_a = ClientContext.CompanyInformation) == null ? void 0 : _a.Companies.length) > 0) {
      const isCustomSourceClient = (yield generationConfigurationClient.get()).data.IsCustomSource;
      setIsCustomSource(isCustomSourceClient);
      if (isCustomSourceClient && !isCustomSourceDataTemplateMenuAlreadyAdded()) {
        addMasterDataSubMenuItem(MASTER_DATA_DATA_TEMPLATE_SUB_MENU);
      } else if (!isCustomSourceClient) {
        removeMasterDataSubMenu(MASTER_DATA_DATA_TEMPLATE_SUB_MENU);
      }
    }
  }), []);
  const menuItemLeafTemplate = (menuItem) => html`
			<a
				href=${removeOptionals(getRouteByKey(menuItem.key))}
				@click=${(e) => {
    if (alreadySignedUp() || ClientContext.IsPspUser) {
      setMenuOpen(false);
    }
    if (!e.ctrlKey) {
      e.preventDefault();
      e.stopPropagation();
      if (alreadySignedUp() || ClientContext.IsPspUser) {
        navigateTo(removeOptionals(getRouteByKey(menuItem.key)));
      }
    }
  }}
				><div
					class=${isKpmgMode ? classNames("py-2 pl-6 text-lg mx-2 mt-1 font-semibold cursor-default hover:bg-kpmg-light-blue rounded-md", {
    "text-white cursor-pointer": alreadySignedUp() || ClientContext.IsPspUser,
    "text-brand-primary-light": !alreadySignedUp() && !ClientContext.IsPspUser,
    "bg-kpmg-light-blue": isCurrentMenu(menuItem.key)
  }) : classNames("py-2 pl-6 text-lg mx-2 mt-1 font-semibold cursor-default rounded-md", {
    "text-brand-primary-dark cursor-pointer hover:bg-brand-secondary-light": alreadySignedUp() || ClientContext.IsPspUser,
    "text-brand-primary-light": !alreadySignedUp() && !ClientContext.IsPspUser,
    "bg-brand-secondary-dark": isCurrentMenu(menuItem.key)
  })}
				>
					${menuItem.icon instanceof TemplateResult ? menuItem.icon : html`
								<svg
									class=${classNames("inline mr-2", { "fill-white": isKpmgMode })}
									width="24"
									height="24"
									viewBox="0 0 500 600"
									fill=${alreadySignedUp() || ClientContext.IsPspUser ? "text-brand-primary-dark" : "text-brand-primary-light"}
								>
									<path d=${menuItem.icon}></path>
								</svg>
						  `}
					${menuItem.label}
				</div>
			</a>
		`;
  const menuItemTemplate = (menuItem) => {
    if (!menuItem.hidden) {
      if (menuItem.subMenus) {
        return html`<div>
					<div>${menuItemLeafTemplate(menuItem)}</div>
					<div class="ml-4 ${!startsWith(menuItem.key) ? "hidden" : ""}">${menuItem.subMenus.map(menuItemTemplate)}</div>
				</div>`;
      } else {
        return menuItemLeafTemplate(menuItem);
      }
    } else {
      return "";
    }
  };
  const htmlGuidesAndTutorials = () => !ClientContext.IsPspUser && !ClientContext.IsPartner ? html`
					<a
						class=${isKpmgMode ? classNames("my-1 mx-2 py-2 pl-6 rounded-md block hover:bg-kpmg-light-blue text-white", {
    "bg-kpmg-light-blue": window.location.pathname === getRouteByKey("guides-and-tutorials")
  }) : classNames("mx-2 py-2 pl-6 hover:bg-brand-secondary-light rounded-md block text-brand-primary-dark", {
    "bg-brand-secondary-dark": window.location.pathname === getRouteByKey("guides-and-tutorials")
  })}
						href=${getRouteByKey("guides-and-tutorials")}
						><div class="flex items-center">
							${svg`
                                <svg class=${classNames("inline mr-1", { "fill-white": isKpmgMode })} width="23" height="23" viewBox="0 0 500 550" fill=${isKpmgMode ? "bg-white" : "bg-brand-primary"}>
                                    <path
                                        d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                                    ></path>
                                </svg>
                            `}
							<span class="text-lg font-semibold ml-1"> ${i18next.t("Guides and Tutorials")}</span>
						</div>
					</a>
					<a
						class=${isKpmgMode ? classNames("my-1 mx-2 py-2 pl-6 rounded-md block hover:bg-kpmg-light-blue text-white", {
    "bg-kpmg-light-blue": window.location.pathname === getRouteByKey("faqs")
  }) : classNames("mx-2 py-2 pl-6 hover:bg-brand-secondary-light rounded-md block text-brand-primary-dark", {
    "bg-brand-secondary-dark": window.location.pathname === getRouteByKey("faqs")
  })}
						href=${getRouteByKey("faqs")}
						><div class="flex items-center">
							${svg`
                                <svg class=${classNames("inline mr-2", { "fill-white": isKpmgMode })} width="23" height="23" viewBox="0 0 500 550" fill=${isKpmgMode ? "bg-white" : "bg-brand-primary"}>
								    <path
								    	d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"
								    ></path>
							    </svg>
                                `}

							<span class="text-lg font-semibold ml-1 mt-1"> ${i18next.t("FAQ")}</span>
						</div>
					</a>
			  ` : "";
  const htmlContactTemplate = () => html`
			<div class="my-4 w-full border border-t border-brand-primary"></div>
			<a
				class=${isKpmgMode ? classNames("my-1 mx-2 py-2 pl-6 rounded-md block hover:bg-kpmg-light-blue text-white", {
    "bg-kpmg-light-blue": window.location.pathname === getRouteByKey("contact")
  }) : classNames("mx-2 py-2 pl-6 hover:bg-brand-secondary-light rounded-md block text-brand-primary-dark", {
    "bg-brand-secondary-dark": window.location.pathname === getRouteByKey("contact")
  })}
				href=${getRouteByKey("contact")}
			>
				<span class="text-lg"> ${i18next.t("Contact")} </span>
			</a>
		`;
  const menuTemplate = () => html`
				<div id="menu" style="width: 280px;" class=${classNames("fixed min-h-screen md:block sm:h-full", {
    "bg-brand-secondary": !isKpmgMode,
    "bg-kpmg-blue": isKpmgMode,
    "block": menuOpen,
    "hidden": !menuOpen
  })}>
					<div class="max-h-screen overflow-y-auto">
						<div class="pl-4 pt-4 hidden md:block">
							<a href="${getRouteByKey("admin")}">
								<div class="text-center">
									<div>
										${isKpmgMode ? html`<img src="/images/logo-primary-white-kpmg.png" alt="KPMG logo" width="90" height="36" />` : html`<img src="/images/logo_primary.png" alt="Tax Desk logo" width="90" height="36" />`}
                                    </div>
								</div>
							</a>
						</div>
						<button type="button" @click=${onToggleMenu} class="block text-text-brand-primary h-16 ml-4 focus:text-brand-primary hover:text-brand-primary focus:outline-none md:invisible">
							<svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
								<path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"></path>
							</svg>
						</button>
						<div class="flex flex-col justify-between h-full">
							<div>${mainMenus.map(menuItemTemplate)}</div>
							<div class="w-full text-brand-primary inset-x bottom-0 mb-4">${htmlGuidesAndTutorials()}${htmlContactTemplate()}</div>
						</div>
					</div>
				</div>
			</div>
		`;
  const htmlCompanySelector = () => html`
			<div class="flex space-x-2 items-center">
				<dc-select
					class="w-64"
					.dataSource=${currentCompanies.map((c) => ({ value: c.id.toString(), label: c.name }))}
					.selectedValues=${currentCompanyId == null ? void 0 : currentCompanyId.toString()}
					@change=${(e) => __async(this, null, function* () {
    return handleCompanyChange(e);
  })}
				></dc-select>
				${!ClientContext.IsPartner ? html`
							<div class="mx-2">
								<button
									type="button"
									@click=${() => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield companiesClient.changeCompany({
          body: new ChangeCompany({ CompanyId: void 0 })
        });
        addNewCompany();
      }),
      (error) => {
        notifyError(error);
      }
    );
  })}
									class="btn btn-primary btn-small"
								>
									${i18next.t("New company")}
								</button>
							</div>
					  ` : ""}
			</div>
		`;
  const headerTemplate = () => {
    var _a;
    return html`
			<div class="fixed bg-white h-16 flex w-full pr-4 justify-between sm:items-center select-none border-b-2 border-gray-300">
				<div class="relative flex items-center justify-between sm:p-0">
					<div>
						${!menuOpen ? html`<button type="button" @click=${onToggleMenu} class="block ml-4 text-brand-primary focus:text-brand-secondary hover:text-brand-secondary focus:outline-none">
									<svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
										<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
									</svg>
							  </button>` : ""}
					</div>
					<div class="pl-2 flex items-center md:hidden">
						<a href="${getRouteByKey("admin")}">
							<div class="text-center">
								<div>
									<img src="/images/logo_primary.png" alt="Tax Desk logo" width="60" />
								</div>
							</div>
						</a>
					</div>
				</div>

				<div class="flex items-center h-full gap-2">
					${PrimaryButton({
      class: classNames("max-w-fit", { hidden: !hasSubmissionOnlySubscription }),
      text: i18next.t("Batch Return Upload"),
      onClick: () => batchReturnUploadModal.setIsOpen(true)
    })}
					${((_a = ClientContext.CompanyInformation) == null ? void 0 : _a.Companies.length) > 0 ? htmlCompanySelector() : ""}
					<span class="border-r border-gray-300 h-full"></span>
					<ww-profile-select></ww-profile-select>
					<span class="border-r border-gray-300 h-full"></span>
					<ww-language-select></ww-language-select>
				</div>
			</div>
		`;
  };
  return html`
		<header class="relative z-10">${headerTemplate()}${menuTemplate()}${notificationHook.mainTemplate()}<dc-notifier></dc-notifier></header>
		${batchReturnUploadModal.modalTemplate()} ${loading.count > 0 ? html` <dc-loader></dc-loader>` : ""}
	`;
}
