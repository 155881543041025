import i18next from "i18next";
export const DEFAULT_CREATE_DATA_TEMPLATE = {
  templateType: "Excel",
  delimitier: ",",
  decimalSeparator: "Comma",
  hasHeaderLine: false,
  isSaleAndPurchaseInOneFile: true,
  isAllCountryInOneFile: false,
  formatSettingsDateFormat: "YearMonthDay"
};
export const FormatTypeMapping = /* @__PURE__ */ new Map([
  ["Excel", [".xlsx", ".xls"]],
  ["Csv", [".csv"]],
  ["Dsv", [".txt"]],
  ["SAPText", [".txt"]]
]);
export const TEMPLATE_FORMAT_OPTIONS = [
  { label: "xlsx / xls", value: "Excel" },
  { label: "csv", value: "Csv" },
  { label: "txt (dsv)", value: "Dsv" },
  { label: "SAP (txt)", value: "SAPText" }
];
export const DECIMAL_SYMBOL_TYPE_OPTIONS = [
  { label: "comma(,)", value: "Comma" },
  { label: "dot(.)", value: "Dot" }
];
export const DELIMITER_OPTIONS = [
  { label: "comma(,)", value: "," },
  { label: "semicolon(;)", value: ";" },
  { label: "space", value: " " },
  { label: "tab", value: "	" }
];
export const DATE_FORMAT_OPTIONS = [
  { label: "YYYY-MM-DD", value: "YearMonthDay" },
  { label: "MM-DD-YYYY", value: "MonthDayYear" },
  { label: "DD-MM-YYYY", value: "DayMonthYear" }
];
export const TAX_DESK_DATA_LABELS = {
  CommodityCode: i18next.t("Commodity Code"),
  CountryOfOrigin: i18next.t("Country of Origin"),
  Currency: i18next.t("Original Currency"),
  CurrencyReporting: i18next.t("Currency Reporting"),
  CustomerCountry: i18next.t("Customer Country"),
  CustomerCountryVatNumberUsed: i18next.t("Customer VAT Number Country"),
  CustomerName: i18next.t("Customer Name"),
  CustomerProvinceCode: i18next.t("Customer Province Code"),
  CustomerVatNumberUsed: i18next.t("Customer VAT Number"),
  DeliveryCondition: i18next.t("Delivery Condition"),
  DocumentIndicator: i18next.t("Document Indicator"),
  ExchangeRate: i18next.t("Exchange Rate"),
  InvoiceDate: i18next.t("Invoice Date"),
  InvoiceNumber: i18next.t("Invoice Number"),
  ItemIdentifier: i18next.t("Item Identifier"),
  ModeOfTransport: i18next.t("Mode of Transport"),
  ProductIdentifier: i18next.t("Product Identifier"),
  Quantity: i18next.t("Quantity"),
  ReportingCountry: i18next.t("Reporting Country"),
  SupplierCountry: i18next.t("Supplier Country"),
  SupplierCountryVatNumberUsed: i18next.t("Supplier VAT Number Country"),
  SupplierName: i18next.t("Supplier Name"),
  SupplierProvinceCode: i18next.t("Supplier Province Code"),
  SupplierVatNumberUsed: i18next.t("Supplier VAT Number"),
  TaxableBasis: i18next.t("Original NET amount"),
  TotalValueLine: i18next.t("Original GROSS amount"),
  TransactionDate: i18next.t("Transaction Date"),
  TransactionType: i18next.t("Transaction Type"),
  ValueVat: i18next.t("Original VAT amount"),
  VatCode: i18next.t("TaxCode"),
  Weight: i18next.t("Weight")
};
