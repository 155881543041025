var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import i18next from "i18next";
import { html } from "lit-html";
import { formatDate } from "GlobalShared/helpers/dateHelper";
const RADIO_HMRC_PERIOD_INPUT_NAME = "radio-hmrc-period-input";
const RADIO_HMRC_PERIOD_INPUT_ID_PREFIX = "radio_ioss_tier_input_";
export const useHMRCModal = (props) => {
  var _a;
  const radioHmrcPeriodInputId = (index) => `${RADIO_HMRC_PERIOD_INPUT_ID_PREFIX}${index}`;
  const onClose = () => {
    setIsUKPrimaryVATSubmissionModalOpen(false);
    setIsConfirmed(false);
    props.setSubmissionPeriodData(void 0);
    setSelectedPeriod(void 0);
  };
  const handleSubmitClick = (hmrcId) => __async(void 0, null, function* () {
    var _a2, _b;
    if (hmrcId) {
      yield props.onSubmit((_a2 = props.hmrcData) == null ? void 0 : _a2.submissionPeriodId, props.loader, hmrcId);
    } else {
      yield props.onSubmit((_b = props.hmrcData) == null ? void 0 : _b.submissionPeriodId, props.loader);
    }
    onClose();
  });
  const [isUKPrimaryVATSubmissionModalOpen, setIsUKPrimaryVATSubmissionModalOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(void 0);
  useEffect(() => {
    var _a2;
    if (((_a2 = props.submissionPeriodData) == null ? void 0 : _a2.periods.length) > 0) {
      setIsUKPrimaryVATSubmissionModalOpen(true);
    }
  }, [(_a = props.submissionPeriodData) == null ? void 0 : _a.periods]);
  const chargedOnSalesAndOtherSuppliesTemplate = () => {
    var _a2, _b;
    return html`
		<span class="p-2 col-span-2 border-t border-solid border-gray-300">Box 1</span><span class="p-2 col-span-8">VAT you charged on sales and other supplies</span
		><span class="p-2 col-span-2">${(_b = (_a2 = props.hmrcData) == null ? void 0 : _a2.vatDeclaration) == null ? void 0 : _b.VATDueOnOutputs}</span>
	`;
  };
  const intraCommunityAcquisitionsTemplate = () => {
    var _a2, _b;
    return html`
		<span class="p-2 col-span-2">Box 2</span><span class="p-2 col-span-8">VAT due in this period on intra-community acquisitions of goods made in Northern Ireland from EU Member States</span
		><span class="p-2 col-span-2">${(_b = (_a2 = props.hmrcData) == null ? void 0 : _a2.vatDeclaration) == null ? void 0 : _b.VATDueOnECAcquisitions}</span>
	`;
  };
  const oweBeforeDeductionsTemplate = () => {
    var _a2, _b;
    return html`
		<span class="p-2 col-span-2">Box 3</span><span class="p-2 col-span-8">VAT you owe before deductions (this is the total of box 1 and 2)</span
		><span class="p-2 col-span-2">${(_b = (_a2 = props.hmrcData) == null ? void 0 : _a2.vatDeclaration) == null ? void 0 : _b.TotalVAT}</span>
	`;
  };
  const claimedBackVatTemplate = () => {
    var _a2, _b;
    return html`
			<span class="p-2 col-span-2">Box 4</span><span class="p-2 col-span-8">VAT you have claimed back</span
			><span class="p-2 col-span-2">${(_b = (_a2 = props.hmrcData) == null ? void 0 : _a2.vatDeclaration) == null ? void 0 : _b.VATReclaimedOnInputs}</span>
		`;
  };
  const returnTotalTemplate = () => {
    var _a2, _b;
    return html`<span class="p-2 col-span-2">Box 5</span><span class="p-2 col-span-8">Return total</span><span class="p-2 col-span-2">${(_b = (_a2 = props.hmrcData) == null ? void 0 : _a2.vatDeclaration) == null ? void 0 : _b.NetVAT}</span> `;
  };
  const totalValueOfSalesTemplate = () => {
    var _a2, _b;
    return html`
		<span class="p-2 col-span-2">Box 6</span><span class="p-2 col-span-8">Total value of sales and other supplies, excluding VAT</span
		><span class="p-2 col-span-2">${(_b = (_a2 = props.hmrcData) == null ? void 0 : _a2.vatDeclaration) == null ? void 0 : _b.NetSalesAndOutputs}</span>
	`;
  };
  const totalValueExcludinVatTemplate = () => {
    var _a2, _b;
    return html`
		<span class="p-2 col-span-2">Box 7</span><span class="p-2 col-span-8">Total value of purchases and other expenses, excluding VAT</span
		><span class="p-2 col-span-2">${(_b = (_a2 = props.hmrcData) == null ? void 0 : _a2.vatDeclaration) == null ? void 0 : _b.NetPurchasesAndInputs}</span>
	`;
  };
  const totalValueOfIntraCommunityDispatchesTemplate = () => {
    var _a2, _b;
    return html`
		<span class="p-2 col-span-2">Box 8</span
		><span class="p-2 col-span-8">Total value of intra-community dispatches of goods and related costs (excluding VAT) from Northern Ireland to EU Member States</span
		><span class="p-2 col-span-2">${(_b = (_a2 = props.hmrcData) == null ? void 0 : _a2.vatDeclaration) == null ? void 0 : _b.NetECSupplies}</span>
	`;
  };
  const totalVAlueOfIntraCommunityAcquisitions = () => {
    var _a2, _b;
    return html`
		<span class="p-2 col-span-2">Box 9</span
		><span class="p-2 col-span-8">Total value of intra-community acquisitions of goods and related costs (excluding VAT) from EU Member States to Northern Ireland</span
		><span class="p-2 col-span-2">${(_b = (_a2 = props.hmrcData) == null ? void 0 : _a2.vatDeclaration) == null ? void 0 : _b.NetECAcquisitions}</span>
	`;
  };
  const UKPrimaryVATSubmissionModalContentTemplate = () => {
    var _a2, _b, _c, _d, _e, _f, _g;
    return ((_a2 = props.submissionPeriodData) == null ? void 0 : _a2.periods) ? choosePeriodModalContentTemplate() : html`
					<div class="flex flex-col gap-4 px-8 py-4">
						<div class="flex flex-wrap gap-4 mb-4">
							<dc-input .label=${"Local VAT Number"} .value=${(_c = (_b = props.hmrcData) == null ? void 0 : _b.vatDeclaration) == null ? void 0 : _c.LocalVatNumber} .readonly=${true}></dc-input>
							<dc-datepicker .label=${"Period Start"} .value=${(_e = (_d = props.hmrcData) == null ? void 0 : _d.vatDeclaration) == null ? void 0 : _e.PeriodStartDate} .readonly=${true}></dc-datepicker>
							<dc-datepicker .label=${"Period End"} .value=${(_g = (_f = props.hmrcData) == null ? void 0 : _f.vatDeclaration) == null ? void 0 : _g.PeriodEndDate} .readonly=${true}></dc-datepicker>
						</div>
						<div class="grid grid-cols-12 divide-y divide-gray-300">
							${chargedOnSalesAndOtherSuppliesTemplate()}${intraCommunityAcquisitionsTemplate()}${oweBeforeDeductionsTemplate()} ${claimedBackVatTemplate()}
							${returnTotalTemplate()}${totalValueOfSalesTemplate()}${totalValueExcludinVatTemplate()}${totalValueOfIntraCommunityDispatchesTemplate()}${totalVAlueOfIntraCommunityAcquisitions()}
						</div>

						<div class="bg-yellow-100 px-4 py-2 rounded-lg">
							<div class="flex flex-col gap-4 text-center">
								<span
									>I confirm that my client has received a copy of the information contained in this return and approved the information as being correct and complete to the best of
									their knowledge and belief.</span
								>
								<dc-checkbox .checked=${isConfirmed} .label=${"Confirm"} @change=${(e) => setIsConfirmed(e.detail.checked)}></dc-checkbox>
							</div>
						</div>
						<div class="mt-8 flex flex-wrap gap-4">
							${SecondaryButton({ class: "w-28", text: "Close", onClick: onClose })}
							${PrimaryButton({
      class: "w-28",
      text: "Submit",
      disabled: !isConfirmed,
      onClick: handleSubmitClick
    })}
						</div>
					</div>
			  `;
  };
  const choosePeriodModalContentTemplate = () => {
    var _a2;
    return html`
		<div class="flex flex-col gap-4 px-8 py-4">
			<div class="text-xl font-semibold">Select one period for submission</div>
			<div>The period indicated does not match with expected period from HMRC. Please double check correct period on the HMRC page.</div>
			${(_a2 = props.submissionPeriodData) == null ? void 0 : _a2.periods.map(
      (period, index) => html`
					<label for=${radioHmrcPeriodInputId(index)} class="btn-radio">
						<input
							type="radio"
							name=${RADIO_HMRC_PERIOD_INPUT_NAME}
							id=${radioHmrcPeriodInputId(index)}
							?checked=${(selectedPeriod == null ? void 0 : selectedPeriod.Id) === period.Id}
							class="cursor-pointer"
							@click=${(_e) => {
        if ((selectedPeriod == null ? void 0 : selectedPeriod.Id) === period.Id) {
          const elem = document.getElementById(`${radioHmrcPeriodInputId(index)}`);
          if (elem) {
            elem.checked = false;
          }
        }
        setSelectedPeriod((selectedPeriod == null ? void 0 : selectedPeriod.Id) === period.Id ? void 0 : period);
      }}
						/>

						<div class="flex gap-4 flex-wrap ml-4 font-normal">
							<div class="flex gap-1"><span class="font-semibold">Period start:</span><span class="font-normal">${formatDate(period.Start)}</span></div>
							<div class="flex gap-1"><span class="font-semibold">Period end:</span><span class="font-normal">${formatDate(period.End)}</span></div>
							<div class="flex gap-1"><span class="font-semibold">Period due date:</span><span class="font-normal">${formatDate(period.DueDate)}</span></div>
						</div>
					</label>
				`
    )}
			<div class="mt-8 flex flex-wrap gap-4">
				${SecondaryButton({ class: "w-28", text: "Close", onClick: onClose })}
				${PrimaryButton({
      class: "w-28",
      text: "Submit",
      disabled: !selectedPeriod,
      onClick: () => handleSubmitClick(selectedPeriod == null ? void 0 : selectedPeriod.Id)
    })}
			</div>
		</div>
	`;
  };
  const uKPrimaryVATSubmissionModalTemplate = () => {
    var _a2;
    return html`
			<dc-modal
				.visible=${((_a2 = props.hmrcData) == null ? void 0 : _a2.vatDeclaration) && isUKPrimaryVATSubmissionModalOpen}
				@close=${onClose}
				.header=${i18next.t("Confirm your submission")}
				.content=${UKPrimaryVATSubmissionModalContentTemplate()}
			>
			</dc-modal>
		`;
  };
  return { uKPrimaryVATSubmissionModalTemplate, setIsUKPrimaryVATSubmissionModalOpen };
};
