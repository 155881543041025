var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { TswagClient } from "./../commons/TswagClient";
import { GenerationConfiguration as GenerationConfigurationModel } from "./../models/V1/GenerationConfiguration/GenerationConfiguration";
import { Error as ApiError } from "./../models/V1/Common/Error/Error";
import { FieldMetadata } from "./../models/V1/GenerationConfiguration/FieldMetadata";
import { CustomSourceUploadResult } from "./../models/V1/ComplianceUploads/CustomSourceUploadResult";
export class GenerationConfiguration {
  /**
   * Creates an instance of GenerationConfiguration.
   * @param {string} [baseUrl]
   * @param {{ fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }} [http]
   * @memberof GenerationConfiguration
   */
  constructor(baseUrl, http) {
    __publicField(this, "http");
    __publicField(this, "baseUrl");
    this.baseUrl = baseUrl;
    this.http = http ? http : window;
  }
  /**
   * @param GetRq_ _getRq
   * @returns Promise<GetRq_>
   * @memberof GenerationConfiguration
   */
  get(options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/GenerationConfiguration", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: GenerationConfigurationModel },
        { statusCode: 404, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateRq_ _updateRq
   * @returns Promise<UpdateRq_>
   * @memberof GenerationConfiguration
   */
  update(pUpdateRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/GenerationConfiguration", "PUT", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pUpdateRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetFieldsMetadataRq_ _getFieldsMetadataRq
   * @returns Promise<GetFieldsMetadataRq_>
   * @memberof GenerationConfiguration
   */
  getFieldsMetadata(pGetFieldsMetadataRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/GenerationConfiguration/metadata", "GET", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addQueryParams([{ name: "uploadType", value: pGetFieldsMetadataRq.uploadType, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: FieldMetadata, isArray: true },
        { statusCode: 400, clazz: ApiError },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetTemplateFileRq_ _getTemplateFileRq
   * @returns Promise<GetTemplateFileRq_>
   * @memberof GenerationConfiguration
   */
  getTemplateFile(pGetTemplateFileRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/GenerationConfiguration/file", "GET", "application/json; charset=UTF-8", "application/json", options);
    client.addQueryParams([{ name: "uploadType", value: pGetTemplateFileRq.uploadType, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, isFile: true },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param GetValidationResultRq_ _getValidationResultRq
   * @returns Promise<GetValidationResultRq_>
   * @memberof GenerationConfiguration
   */
  getValidationResult(pGetValidationResultRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/GenerationConfiguration/validation", "POST", "multipart/form-data", "text/plain, application/json, text/json", options);
    client.addQueryParams([{ name: "uploadType", value: pGetValidationResultRq.uploadType, required: true }]);
    client.addFormData("multipart/form-data", [{ name: "file", value: pGetValidationResultRq.file, required: true }]);
    const respDescriptor = {
      headerParams: [],
      items: [
        { statusCode: 200, clazz: CustomSourceUploadResult },
        { statusCode: 404, clazz: ApiError },
        { statusCode: 409, clazz: ApiError }
      ]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateCustomSourceMappingRq_ _updateCustomSourceMappingRq
   * @returns Promise<UpdateCustomSourceMappingRq_>
   * @memberof GenerationConfiguration
   */
  updateCustomSourceMapping(pUpdateCustomSourceMappingRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/GenerationConfiguration/mapping", "PUT", "application/json; charset=UTF-8", "text/plain, application/json, text/json", options);
    client.addBody({ value: pUpdateCustomSourceMappingRq.body });
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
  /**
   * @param UpdateTemplateReferenceRq_ _updateTemplateReferenceRq
   * @returns Promise<UpdateTemplateReferenceRq_>
   * @memberof GenerationConfiguration
   */
  updateTemplateReference(pUpdateTemplateReferenceRq, options = {}) {
    const client = new TswagClient(this.baseUrl + "/api/v1/GenerationConfiguration/templatereference", "PUT", "multipart/form-data", "text/plain, application/json, text/json", options);
    client.addQueryParams([{ name: "uploadType", value: pUpdateTemplateReferenceRq.uploadType, required: true }]);
    client.addFormData("multipart/form-data", [{ name: "file", value: pUpdateTemplateReferenceRq.file }]);
    const respDescriptor = {
      headerParams: [],
      items: [{ statusCode: 204 }, { statusCode: 400, clazz: ApiError }, { statusCode: 404, clazz: ApiError }, { statusCode: 409, clazz: ApiError }, { statusCode: 422, clazz: ApiError }]
    };
    return client.fetch(this.http, respDescriptor);
  }
}
export var GetFieldsMetadataUploadType = /* @__PURE__ */ ((GetFieldsMetadataUploadType2) => {
  GetFieldsMetadataUploadType2["Sale"] = "Sale";
  GetFieldsMetadataUploadType2["Purchase"] = "Purchase";
  GetFieldsMetadataUploadType2["AllInOne"] = "AllInOne";
  return GetFieldsMetadataUploadType2;
})(GetFieldsMetadataUploadType || {});
export var GetTemplateFileUploadType = /* @__PURE__ */ ((GetTemplateFileUploadType2) => {
  GetTemplateFileUploadType2["Sale"] = "Sale";
  GetTemplateFileUploadType2["Purchase"] = "Purchase";
  GetTemplateFileUploadType2["AllInOne"] = "AllInOne";
  return GetTemplateFileUploadType2;
})(GetTemplateFileUploadType || {});
export var GetValidationResultUploadType = /* @__PURE__ */ ((GetValidationResultUploadType2) => {
  GetValidationResultUploadType2["Sale"] = "Sale";
  GetValidationResultUploadType2["Purchase"] = "Purchase";
  GetValidationResultUploadType2["AllInOne"] = "AllInOne";
  return GetValidationResultUploadType2;
})(GetValidationResultUploadType || {});
export var UpdateTemplateReferenceUploadType = /* @__PURE__ */ ((UpdateTemplateReferenceUploadType2) => {
  UpdateTemplateReferenceUploadType2["Sale"] = "Sale";
  UpdateTemplateReferenceUploadType2["Purchase"] = "Purchase";
  UpdateTemplateReferenceUploadType2["AllInOne"] = "AllInOne";
  return UpdateTemplateReferenceUploadType2;
})(UpdateTemplateReferenceUploadType || {});
