var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { templateTitle } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import { useEffect, useLoadingReducer, useQueryGridState, useState } from "GlobalShared/haunted/CustomHooks";
import { useAwesomeValidator, Validation } from "GlobalShared/validation/AwesomeValidator";
import { isEmpty, REQUIRED_MESSAGE } from "GlobalShared/helpers/stringHelper";
import {
  DEFAULT_TAX_CODE,
  TaxCodeFieldNames,
  mapTaxCode,
  FILTER_VAT_RATE_TYPES_DATA_SOURCE
} from "./TaxCodeModels";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import classNames from "classnames";
const DEFAULT_FILTERS = [];
const NO_VAT_RATE_AVAILABLE_MESSAGE = "No VAT Rate available";
export function useSetupTaxCodeStep(props) {
  const resetAll = () => {
    setIsModalOpen(false);
    setModalState(DEFAULT_TAX_CODE);
  };
  const handleEditRow = (item) => {
    setIsEditMode(true);
    setModalState(item);
    setIsModalOpen(true);
  };
  const searchAndSetTaxCodes = (showOnlyMissingConfig = false, goods, rates) => __async(this, null, function* () {
    const result = yield props.searchTaxCodes({ gridState, onlyMissing: showOnlyMissingConfig }, loader);
    const mappedResult = result == null ? void 0 : result.TaxCodes.map((tc) => mapTaxCode(tc, goods ? goods : typeOfGoods, rates ? rates : vatRates));
    setSearchResult({ taxcodes: mappedResult, totalCount: result == null ? void 0 : result.Count });
  });
  const saveEditedRow = () => __async(this, null, function* () {
    var _a;
    yield props.updateTaxCode(
      modalState.id,
      __spreadProps(__spreadValues({}, modalState), {
        reverseCharge: modalState.reverseCharge / 100,
        transactionTypeCode: modalState.transactionTypeCode,
        typeOfGoods: (_a = typeOfGoods.find((g) => g.name === modalState.typeOfGoods)) == null ? void 0 : _a.id
      }),
      loader
    );
    yield searchAndSetTaxCodes(showMissingConfigOnly);
    setIsEditMode(false);
  });
  const addNewRow = () => __async(this, null, function* () {
    var _a;
    yield props.addTaxCode(
      __spreadProps(__spreadValues({}, modalState), {
        reverseCharge: modalState.reverseCharge / 100,
        transactionTypeCode: modalState.transactionTypeCode,
        typeOfGoods: (_a = typeOfGoods.find((g) => g.name === modalState.typeOfGoods)) == null ? void 0 : _a.id
      }),
      loader
    );
    yield searchAndSetTaxCodes();
  });
  const getVatRates = () => {
    var _a;
    const rates = (_a = vatRates == null ? void 0 : vatRates.find((v) => v.countryCode === modalState.countryCode)) == null ? void 0 : _a.typeRateOptions.map((r) => ({ label: `${r.type} - ${r.rate}%`, value: r.rate.toString() }));
    return !rates || (rates == null ? void 0 : rates.length) === 0 ? [NO_VAT_RATE_AVAILABLE_MESSAGE] : rates;
  };
  const onResetFilters = () => setGridState(__spreadProps(__spreadValues({}, gridState), {
    pageIndex: 0,
    appliedFilters: DEFAULT_FILTERS
  }));
  const onSortChanged = (e) => {
    const OrderByValue = e.detail.orderBy;
    const OrderDirValue = e.detail.orderDir;
    setGridState(__spreadProps(__spreadValues({}, gridState), {
      orderBy: OrderByValue,
      orderDir: OrderDirValue
    }));
  };
  const onPageChanged = (e) => {
    const pageIndex = e.detail.selectedPageIndex;
    const pageSize = e.detail.selectedPageSize;
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex, pageSize }));
  };
  const onFilterChanged = (e) => __async(this, null, function* () {
    const newGridFilter = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridFilter);
  });
  const handleShowMissingConfigOnlyChange = (e) => __async(this, null, function* () {
    yield searchAndSetTaxCodes(e.detail.checked);
    setShowMissingConfigOnly(e.detail.checked);
  });
  const [searchResult, setSearchResult] = useState(void 0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalState, setModalState] = useState(DEFAULT_TAX_CODE);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [vatRates, setVatRates] = useState([]);
  const [typeOfGoods, setTypeOfGoods] = useState([]);
  const [loading, dispatchLoading] = useLoadingReducer();
  const [gridState, setGridState] = useQueryGridState({
    pageIndex: 0,
    appliedFilters: [],
    pageSize: 20,
    orderBy: "ClientTaxCode",
    orderDir: "asc"
  });
  const [showMissingConfigOnly, setShowMissingConfigOnly] = useState(false);
  const loader = {
    start: () => dispatchLoading("inc"),
    stop: () => dispatchLoading("dec")
  };
  const validator = useAwesomeValidator(
    [
      Validation.ruleFor("clientTaxCode", (m) => m.clientTaxCode).isRequired().min(1, i18next.t("Tax code cannot be shorter than 1 character")).max(10, i18next.t("Tax code cannot be longer than 10 characters")),
      Validation.ruleFor("countryCode", (m) => m.countryCode).isRequired(),
      Validation.ruleFor("transactionTypeCode", (m) => m.transactionTypeCode).isRequired(),
      Validation.ruleFor("vatRate", (m) => m.vatRate).isRequired().isNotIn([NO_VAT_RATE_AVAILABLE_MESSAGE], i18next.t("VAT Rate is not available for the selected reporting country")).when((m) => !isEmpty(m.countryCode)),
      Validation.ruleFor("typeOfGoods", (m) => m.typeOfGoods).isRequired(),
      Validation.ruleFor("reverseCharge", (m) => m.reverseCharge).max(100, i18next.t("Reverse Charge percent cannot be more than 100")).min(0, i18next.t("Reverse Charge percent cannot be less than 0")).when((m) => m.reverseCharge !== void 0 && !isNaN(m.reverseCharge))
    ],
    () => {
      isEditMode ? saveEditedRow() : addNewRow();
      setIsModalOpen(false);
    }
  );
  useEffect(() => {
    if (modalState) {
      validator.validate(modalState);
    }
  }, [modalState]);
  useEffect(() => {
    if (!isModalOpen) {
      validator.clear();
      setModalState(DEFAULT_TAX_CODE);
    }
  }, [isModalOpen]);
  useEffect(() => __async(this, null, function* () {
    if (typeOfGoods.length === 0 || vatRates.length === 0) {
      const types = yield props.getTransactionTypes(loader);
      const rates = yield props.getVATRateTypeSettings(loader);
      const goods = yield props.getTypeOfGoods(loader);
      yield searchAndSetTaxCodes(false, goods, rates);
      setTransactionTypes(types);
      setVatRates(rates);
      setTypeOfGoods(goods);
      return;
    }
    yield searchAndSetTaxCodes();
  }), [gridState]);
  const resetFilterButtonTemplate = () => {
    var _a;
    return ((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html` <div class="self-end">${PrimaryButton({ class: "max-w-fit", text: i18next.t("Reset filters"), onClick: onResetFilters })}</div> ` : "";
  };
  const gridTemplate = () => {
    const vm = {
      columns: [
        {
          field: TaxCodeFieldNames.ClientTaxCode,
          label: i18next.t("Tax Code"),
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "string" },
          columnType: "string"
        },
        {
          field: TaxCodeFieldNames.Country,
          label: i18next.t("Reporting Country"),
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: props.reportingCountries.map((rc) => ({ label: rc.Name, value: rc.Code })),
            searchable: true,
            searchProps: { type: "includes" }
          },
          columnType: "enum"
        },
        {
          field: TaxCodeFieldNames.TransactionType,
          label: i18next.t("Transaction Type"),
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: transactionTypes == null ? void 0 : transactionTypes.map((t) => ({ label: `${t.description} (${t.code})`, value: t.code })),
            multiSelect: true,
            searchProps: { type: "includes" }
          },
          columnType: "enum"
        },
        {
          field: TaxCodeFieldNames.VatRate,
          label: i18next.t("VAT Rate Type"),
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "select", dataSource: FILTER_VAT_RATE_TYPES_DATA_SOURCE, searchable: true, multiSelect: true, searchProps: { type: "includes" } },
          columnType: "enum"
        },
        {
          field: TaxCodeFieldNames.TypeOfGoods,
          label: i18next.t("Type of Goods"),
          sortable: true,
          filterable: true,
          filterDescriptor: {
            type: "select",
            dataSource: typeOfGoods == null ? void 0 : typeOfGoods.map((t) => ({ label: t.name, value: t.id })),
            multiSelect: true,
            searchProps: { type: "includes" }
          },
          columnType: "enum"
        },
        {
          field: TaxCodeFieldNames.ReverseCharge,
          label: `${i18next.t("Reverse Carge")} %`,
          sortable: true,
          filterable: true,
          filterDescriptor: { type: "number", from: true, to: true },
          columnType: "number"
        },
        {
          field: TaxCodeFieldNames.Actions,
          label: i18next.t("Actions"),
          sortable: false,
          filterable: false,
          columnType: "string"
        }
      ],
      data: searchResult == null ? void 0 : searchResult.taxcodes,
      cellTemplate: (index, field) => {
        var _a, _b, _c, _d, _e;
        const item = searchResult == null ? void 0 : searchResult.taxcodes[index];
        if (field === TaxCodeFieldNames.ClientTaxCode) {
          return (_a = item.clientTaxCode) != null ? _a : "";
        } else if (field === TaxCodeFieldNames.Country) {
          return (_b = item.countryCode) != null ? _b : "";
        } else if (field === TaxCodeFieldNames.TransactionType) {
          return `${(_d = (_c = transactionTypes.find((t) => t.code === item.transactionTypeCode)) == null ? void 0 : _c.description) != null ? _d : ""} ${item.transactionTypeCode ? `(${item.transactionTypeCode})` : ""}`;
        } else if (field === TaxCodeFieldNames.VatRate) {
          return !isEmpty(item.vatRate) && item.vatRateType ? `${item.vatRateType} - ${item.vatRate}%` : "";
        } else if (field === TaxCodeFieldNames.TypeOfGoods) {
          return (_e = item.typeOfGoods) != null ? _e : "";
        } else if (field === TaxCodeFieldNames.ReverseCharge) {
          return item.reverseCharge !== void 0 ? `${item.reverseCharge}%` : "";
        } else if (field === TaxCodeFieldNames.Actions) {
          return html` <div class="m-1">${PrimaryButton({ class: "w-24", text: "Edit", size: "small", onClick: () => handleEditRow(item) })}</div> `;
        } else {
          return "";
        }
      },
      appliedFilters: gridState.appliedFilters,
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: searchResult == null ? void 0 : searchResult.totalCount
      }
    };
    return html`
			<div class="flex flex-col gap-4">
				<dc-checkbox2 .label=${i18next.t("Show missing configuration only")} .checked=${showMissingConfigOnly} @change=${handleShowMissingConfigOnlyChange}></dc-checkbox2>
				${resetFilterButtonTemplate()}<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>
			</div>
		`;
  };
  const modalContentTemplate = () => {
    var _a;
    return html`
		<div class="flex flex-col gap-8 py-4 px-6">
			<div class="flex justify-center">
				<div class="border-r-2 border-solid border-brand-primary pr-8 basis-1/2">
					<dc-input
						.label=${`${i18next.t("Tax Code")}*`}
						.value=${modalState.clientTaxCode}
						.validationMessage=${validator.getMessage("clientTaxCode")}
						@change=${(e) => setModalState(__spreadProps(__spreadValues({}, modalState), { clientTaxCode: e.detail.value }))}
					></dc-input>
				</div>
				<div class="flex flex-col gap-4 pl-8 basis-1/2">
					<dc-select
						.label=${`${i18next.t("Reporting Country")}*`}
						.dataSource=${props.reportingCountries.map((c) => ({ label: c.Name, value: c.Code }))}
						.selectedValues=${modalState.countryCode}
						.validationMessage=${validator.getMessage("countryCode")}
						.filterable=${true}
						.filterProps=${{ type: "includes", ignorcase: true }}
						@change=${(e) => setModalState(__spreadProps(__spreadValues({}, modalState), { countryCode: e.detail.selectedValue, vatRate: "", vatRateType: "" }))}
					></dc-select>
					<dc-select
						.label=${`${i18next.t("Transaction Type")}*`}
						.dataSource=${transactionTypes == null ? void 0 : transactionTypes.map((t) => ({ value: t.code, label: `${t.description} (${t.code})` }))}
						.filterable=${true}
						.filterProps=${{ type: "includes" }}
						.selectedValues=${modalState.transactionTypeCode}
						.validationMessage=${validator.getMessage("transactionTypeCode")}
						@change=${(e) => setModalState(__spreadProps(__spreadValues({}, modalState), { transactionTypeCode: e.detail.selectedValue }))}
					></dc-select>
					<dc-select
						class=${classNames({ "mb-4": ((_a = validator.getMessage("vatRate")) == null ? void 0 : _a.length) > 0 && validator.getMessage("vatRate") !== REQUIRED_MESSAGE })}
						.label=${`${i18next.t("VAT Rate")}*`}
						.dataSource=${getVatRates()}
						.selectedValues=${modalState.vatRate}
						.validationMessage=${validator.getMessage("vatRate")}
						.readonly=${isEmpty(modalState.countryCode)}
						@change=${(e) => {
      var _a2, _b;
      return setModalState(__spreadProps(__spreadValues({}, modalState), {
        vatRate: e.detail.selectedValue,
        vatRateType: (_b = (_a2 = vatRates.find((v) => v.countryCode === modalState.countryCode)) == null ? void 0 : _a2.typeRateOptions.find((r) => r.rate === Number(e.detail.selectedValue))) == null ? void 0 : _b.type
      }));
    }}
					></dc-select>
					<dc-select
						.label=${`${i18next.t("Type of Goods")}*`}
						.dataSource=${typeOfGoods == null ? void 0 : typeOfGoods.map((g) => g.name)}
						.selectedValues=${modalState.typeOfGoods}
						.validationMessage=${validator.getMessage("typeOfGoods")}
						@change=${(e) => setModalState(__spreadProps(__spreadValues({}, modalState), { typeOfGoods: e.detail.selectedValue }))}
					></dc-select>
					<dc-input
						.label=${i18next.t("Recovery Percentage")}
						.note=${i18next.t("Please use whole number between 0-100. If not filled out, and Recovery applies the default value of 100% will apply.")}
						.value=${modalState.reverseCharge}
						.type=${"number"}
						.min=${0}
						.max=${100}
						.validationMessage=${validator.getMessage("reverseCharge")}
						@change=${(e) => setModalState(__spreadProps(__spreadValues({}, modalState), { reverseCharge: isEmpty(e.detail.value) ? void 0 : Number(e.detail.value) }))}
					></dc-input>
				</div>
			</div>

			<div class="flex gap-4 flex-wrap">
				${SecondaryButton({
      class: "w-32",
      text: "Cancel",
      onClick: () => {
        resetAll();
        setIsEditMode(false);
      }
    })}
				${PrimaryButton({
      class: "w-32",
      text: isEditMode ? "Save" : "Add",
      onClick: () => validator.validateAndDispatch(modalState)
    })}
			</div>
		</div>
	`;
  };
  const template = () => html`
		<div class="m-8 flex flex-col gap-4">
			<div>${templateTitle(i18next.t("Setup Tax Codes"))}</div>
			<div>${i18next.t("Add and configure your own tax codes")}</div>
			${PrimaryButton({ class: "w-48 my-6", text: i18next.t("Add Tax Code"), onClick: () => setIsModalOpen(true) })} ${gridTemplate()}
			<div class="w-full flex gap-4 justify-start">
				${SecondaryButton({ class: "w-32 my-6", text: i18next.t("Previous"), onClick: () => props.setStep(1) })}
				${PrimaryButton({
    class: "w-32 my-6",
    text: i18next.t("Next"),
    onClick: () => props.setStep(3)
  })}
			</div>
		</div>

		<dc-modal
			class="tax-code-modal"
			.visible=${isModalOpen}
			@close=${() => {
    resetAll();
    setIsEditMode(false);
  }}
			.header=${isEditMode ? i18next.t("Edit Tax Code") : i18next.t("Tax Code")}
			.content=${modalContentTemplate()}
		>
		</dc-modal>
		${loading.count > 0 ? html` <dc-loader></dc-loader>` : ""}
	`;
  return { template };
}
