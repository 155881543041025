var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useLoadingReducer, useState } from "GlobalShared/haunted/CustomHooks";
import { html } from "lit-html";
import i18next from "i18next";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import {
  getEslFrequencyOptions,
  getFrequencyDataSource,
  getIntrastatFrequencyOptions,
  getLocalListingFrequencyOptions,
  getPrimaryVatFrequencyOptions,
  getSecondaryVatFrequencyOptions,
  getVlsFrequencyOptions
} from "./service-helpers";
import { mapBooleanToState, mapStateToBoolean } from "GlobalShared/helpers/booleanHelper";
import { notifyErrorWithWarning } from "GlobalShared/helpers/errorHelper";
export const useFrequencyModal = (props) => {
  const save = () => __async(void 0, null, function* () {
    if (validateAllFields()) {
      const newFrequencyConfigs = frequencies.filter((f) => f.Id === void 0);
      const frequencyConfigIdsToBeRemoved = props.currentFrequencies.filter((cf) => !frequencies.some((f) => f.Id === cf.Id)).map((f) => f.Id);
      if (newFrequencyConfigs.length > 0 || frequencyConfigIdsToBeRemoved.length > 0) {
        try {
          dispatchLoading("inc");
          yield props.save(sendEmail, newFrequencyConfigs, frequencyConfigIdsToBeRemoved);
          setShowModal(false);
        } catch (error) {
          notifyErrorWithWarning(error);
        } finally {
          dispatchLoading("dec");
        }
      }
    }
  });
  const addFrequency = () => {
    const now = /* @__PURE__ */ new Date();
    setFrequencies(
      frequencies.concat([
        {
          FrequencyType: void 0,
          EFiling: void 0,
          FilingExtension: void 0,
          ValidFromYear: now.getFullYear(),
          ValidFromMonth: now.getMonth() + 1,
          validationMessages: {}
        }
      ])
    );
  };
  const removeFrequency = (index) => {
    setFrequencies(frequencies.filter((_, i) => i !== index));
  };
  const validateAllFields = () => {
    let isValid = true;
    const now = /* @__PURE__ */ new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1;
    setGeneralValidationMessage(void 0);
    if (frequencies.filter((freq) => freq.ValidFromYear <= currentYear || freq.ValidFromYear === currentYear && freq.ValidFromMonth <= currentMonth).length === 0) {
      isValid = false;
      setGeneralValidationMessage("At least one frequency must be valid for the current period");
    }
    setFrequencies(
      frequencies.map((f) => {
        const { eFilingOptions, filingExtensionOptions } = getFrequencyOptions(f.FrequencyType);
        for (const fieldType of ["Freq", "ValidFromMonth", "ValidFromYear", "EFiling", "FilingExtension"]) {
          const validationMessage = getValidationMessage(f, fieldType, eFilingOptions, filingExtensionOptions);
          isValid = isValid && validationMessage === void 0;
          f.validationMessages[fieldType] = validationMessage;
        }
        return f;
      })
    );
    return isValid;
  };
  const changeField = (index, changeFunc) => {
    setFrequencies(
      frequencies.map((f, i) => {
        return i === index ? changeFunc(f) : f;
      })
    );
  };
  const getValidationMessage = (obj, fieldType, eFilingOptions, filingExtensionOptions) => {
    let validationMessage;
    if (fieldType === "Freq") {
      if (obj.FrequencyType === void 0) {
        validationMessage = "Required";
      }
    }
    if (fieldType === "ValidFromYear") {
      if (obj.ValidFromYear === void 0) {
        validationMessage = "Required";
      } else if (obj.ValidFromYear < 1900 || obj.ValidFromYear > 2100) {
        validationMessage = "Must be between 1900 and 2100";
      }
    }
    if (fieldType === "ValidFromMonth") {
      if (obj.ValidFromMonth === void 0) {
        validationMessage = "Required";
      } else if (obj.ValidFromMonth < 1 || obj.ValidFromMonth > 12) {
        validationMessage = "Must be between 1 and 12";
      }
    }
    if (fieldType === "EFiling") {
      if (obj.EFiling === void 0 && eFilingOptions.length > 0) {
        validationMessage = "Required";
      }
    }
    if (fieldType === "FilingExtension") {
      if (obj.FilingExtension === void 0 && filingExtensionOptions.length > 0) {
        validationMessage = "Required";
      }
    }
    return validationMessage;
  };
  const getFrequencyOptions = (frequencyType) => {
    let eFilingOptions;
    let filingExtensionOptions;
    if (props.returnType === "PrimaryVAT") {
      const { primaryVatEFilingOptions, primaryVatFilingExtensionOptions } = getPrimaryVatFrequencyOptions(props.submissionConfig, props.serviceCountryCode, frequencyType);
      eFilingOptions = primaryVatEFilingOptions;
      filingExtensionOptions = primaryVatFilingExtensionOptions;
    }
    if (props.returnType === "VLS") {
      const { vlsEFilingOptions, vlsFilingExtensionOptions } = getVlsFrequencyOptions(props.submissionConfig, props.serviceCountryCode, frequencyType);
      eFilingOptions = vlsEFilingOptions;
      filingExtensionOptions = vlsFilingExtensionOptions;
    }
    if (props.returnType === "ESL") {
      const { eslEFilingOptions, eslFilingExtensionOptions } = getEslFrequencyOptions(props.submissionConfig, props.serviceCountryCode, frequencyType);
      eFilingOptions = eslEFilingOptions;
      filingExtensionOptions = eslFilingExtensionOptions;
    }
    if (props.returnType === "SecondaryVAT") {
      const { secondaryVatEFilingOptions, secondaryVatFilingExtensionOptions } = getSecondaryVatFrequencyOptions(props.submissionConfig, props.serviceCountryCode, frequencyType);
      eFilingOptions = secondaryVatEFilingOptions;
      filingExtensionOptions = secondaryVatFilingExtensionOptions;
    }
    if (props.returnType === "Intrastat") {
      const { intrastatEFilingOptions, intrastatFilingExtensionOptions } = getIntrastatFrequencyOptions(props.submissionConfig, props.serviceCountryCode, frequencyType);
      eFilingOptions = intrastatEFilingOptions;
      filingExtensionOptions = intrastatFilingExtensionOptions;
    }
    if (props.returnType === "LocalListing") {
      const { localListingEFilingOptions, localListingFilingExtensionOptions } = getLocalListingFrequencyOptions(props.submissionConfig, props.serviceCountryCode, frequencyType);
      eFilingOptions = localListingEFilingOptions;
      filingExtensionOptions = localListingFilingExtensionOptions;
    }
    return { eFilingOptions, filingExtensionOptions };
  };
  const showToggle = (options, val, defaultValue) => {
    return options.length === 1 && val !== void 0 && val !== defaultValue || options.length === 2;
  };
  const [frequencyDataSource, setFrequencyDataSource] = useState([]);
  const [frequencies, setFrequencies] = useState(void 0);
  const [generalValidationMessage, setGeneralValidationMessage] = useState(void 0);
  const [sendEmail, setSendEmail] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [loading, dispatchLoading] = useLoadingReducer();
  const show = () => {
    setShowModal(true);
  };
  useEffect(() => {
    if (props !== void 0 && props.currentFrequencies !== void 0) {
      if (props.returnType === "PrimaryVAT") {
        setFrequencyDataSource(getFrequencyDataSource(props.submissionConfig, props.serviceCountryCode, "PrimaryVAT"));
      } else if (props.returnType === "VLS") {
        setFrequencyDataSource(getFrequencyDataSource(props.submissionConfig, props.serviceCountryCode, "VLS"));
      } else if (props.returnType === "ESL") {
        setFrequencyDataSource(getFrequencyDataSource(props.submissionConfig, props.serviceCountryCode, "ESL"));
      } else if (props.returnType === "SecondaryVAT") {
        setFrequencyDataSource(getFrequencyDataSource(props.submissionConfig, props.serviceCountryCode, "SecondaryVAT"));
      } else if (props.returnType === "Intrastat") {
        setFrequencyDataSource(getFrequencyDataSource(props.submissionConfig, props.serviceCountryCode, "Intrastat"));
      } else if (props.returnType === "LocalListing") {
        setFrequencyDataSource(getFrequencyDataSource(props.submissionConfig, props.serviceCountryCode, "LocalListing"));
      } else {
        setFrequencyDataSource([]);
      }
      setGeneralValidationMessage(void 0);
      setFrequencies(props.currentFrequencies.map((f) => __spreadProps(__spreadValues({}, f), { validationMessages: {} })));
      setSendEmail(true);
    }
  }, [props]);
  const template = () => {
    return html`${showModal ? html`
					<div>
						<dc-modal
							.visible=${showModal}
							@close=${() => setShowModal(false)}
							.header=${`Edit ${props.returnType} frequency`}
							.content=${html`<div>
								<div class="space-y-4">
									${frequencies == null ? void 0 : frequencies.map((freq, i) => {
      const { eFilingOptions, filingExtensionOptions } = getFrequencyOptions(freq.FrequencyType);
      return html`<div class="flex space-x-4 items-end">
											<dc-select
												.dataSource=${frequencyDataSource}
												.selectedValues=${freq.FrequencyType}
												.validationMessage=${frequencies[i].validationMessages.Freq}
												.label=${"Frequency"}
												.readonly=${freq.Id !== void 0}
												@change=${(e) => {
        changeField(i, (f) => {
          const newFreqType = e.detail.selectedValue;
          const options = getFrequencyOptions(newFreqType);
          const newFreq = __spreadProps(__spreadValues({}, f), {
            FrequencyType: newFreqType,
            EFiling: options.eFilingOptions.length === 1 ? options.eFilingOptions[0] === "Yes" : void 0,
            FilingExtension: options.filingExtensionOptions.length === 1 ? options.filingExtensionOptions[0] === "Yes" : void 0
          });
          newFreq.validationMessages.Freq = getValidationMessage(newFreq, "Freq", eFilingOptions, filingExtensionOptions);
          return newFreq;
        });
      }}
											></dc-select>
											<dc-input
												class="w-32"
												.label=${"From year"}
												.value=${freq.ValidFromYear}
												.validationMessage=${frequencies[i].validationMessages.ValidFromYear}
												.readonly=${freq.Id !== void 0}
												@change=${(e) => {
        changeField(i, (f) => {
          const newFreq = __spreadProps(__spreadValues({}, f), {
            ValidFromYear: toSafeNumber(e.detail.value)
          });
          newFreq.validationMessages.ValidFromYear = getValidationMessage(newFreq, "ValidFromYear", eFilingOptions, filingExtensionOptions);
          return newFreq;
        });
      }}
											></dc-input>
											<dc-input
												class="w-32"
												.label=${"From month"}
												.value=${freq.ValidFromMonth}
												.validationMessage=${frequencies[i].validationMessages.ValidFromMonth}
												.readonly=${freq.Id !== void 0}
												@change=${(e) => {
        changeField(i, (f) => {
          const newFreq = __spreadProps(__spreadValues({}, f), {
            ValidFromMonth: toSafeNumber(e.detail.value)
          });
          newFreq.validationMessages.ValidFromMonth = getValidationMessage(newFreq, "ValidFromMonth", eFilingOptions, filingExtensionOptions);
          return newFreq;
        });
      }}
											></dc-input>
											${showToggle(eFilingOptions, freq.EFiling, true) ? html`<dc-toggle
														.label=${"E-filing"}
														.state=${mapBooleanToState(freq.EFiling)}
														.validationMessage=${frequencies[i].validationMessages.EFiling}
														.readonly=${freq.Id !== void 0 || eFilingOptions.length === 1}
														@change=${(e) => {
        changeField(i, (f) => {
          const newFreq = __spreadProps(__spreadValues({}, f), {
            EFiling: mapStateToBoolean(e.detail.state)
          });
          newFreq.validationMessages.EFiling = getValidationMessage(newFreq, "EFiling", eFilingOptions, filingExtensionOptions);
          return newFreq;
        });
      }}
												  ></dc-toggle>` : ""}
											${showToggle(filingExtensionOptions, freq.FilingExtension, false) ? html`<dc-toggle
														.label=${"Filing extension"}
														.state=${mapBooleanToState(freq.FilingExtension)}
														.validationMessage=${frequencies[i].validationMessages.FilingExtension}
														.readonly=${freq.Id !== void 0 || filingExtensionOptions.length === 1}
														@change=${(e) => {
        changeField(i, (f) => {
          const newFreq = __spreadProps(__spreadValues({}, f), {
            FilingExtension: mapStateToBoolean(e.detail.state)
          });
          newFreq.validationMessages.FilingExtension = getValidationMessage(newFreq, "FilingExtension", eFilingOptions, filingExtensionOptions);
          return newFreq;
        });
      }}
												  ></dc-toggle>` : ""}
											<div>
												<button class="btn btn-primary" @click=${() => removeFrequency(i)}>Remove</button>
											</div>
										</div>`;
    })}
									<div>
										<button class="btn btn-primary mt-4" @click=${addFrequency}>+</button>
									</div>
									${generalValidationMessage ? html` <div class="text-red-500">${generalValidationMessage}</div> ` : ""}
								</div>
								<div class="mt-4">
									<dc-checkbox2
										.checked=${sendEmail}
										.label=${i18next.t("Send email")}
										@change=${(e) => {
      setSendEmail(e.detail.checked);
    }}
									></dc-checkbox2>
								</div>
								<div class="mt-4 space-x-4">
									<button class="btn btn-primary" @click=${() => setShowModal(false)}>Cancel</button>
									<button
										class="btn btn-primary"
										?disabled=${loading.count > 0}
										@click=${() => __async(void 0, null, function* () {
      yield save();
    })}
									>
										Save
									</button>
								</div>
							</div>`}
						>
						</dc-modal>
					</div>
			  ` : html``}`;
  };
  return { show, template };
};
