var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { AdditionalInfo } from "./AdditionalInfo";
export class HungarianAdditionalInfo extends AdditionalInfo {
  constructor(_data) {
    super();
    /**
     */
    __publicField(this, "AuthCertUploaded");
    /**
     */
    __publicField(this, "EncryptionCertUploaded");
    /**
     */
    __publicField(this, "OnBehalfOf");
    /**
     */
    __publicField(this, "SignatureCertUploaded");
    /**
     */
    __publicField(this, "UserId");
    /**
     * Adds support for polymorphism. The discriminator is the schema property
     * name that is used to differentiate between other schema that inherit this
     * schema.
     *
     * @protected
     * @type {string}
     * @memberof HungarianAdditionalInfo
     */
    __publicField(this, "$type", "V1.Cesop.Psps.Extensions.HungarianAdditionalInfo");
    if (_data) {
      for (const property in _data) {
        if (_data.hasOwnProperty(property)) {
          this[property] = _data[property];
        }
      }
    }
  }
  /**
   * Initializes a new model instance.
   */
  init(_data) {
    super.init(_data);
    if (_data) {
      this.AuthCertUploaded = _data["AuthCertUploaded"];
      this.EncryptionCertUploaded = _data["EncryptionCertUploaded"];
      this.OnBehalfOf = _data["OnBehalfOf"];
      this.SignatureCertUploaded = _data["SignatureCertUploaded"];
      this.UserId = _data["UserId"];
    }
    return this;
  }
  toJSON(_data) {
    _data = typeof _data === "object" ? _data : {};
    super.toJSON(_data);
    _data["AuthCertUploaded"] = this.AuthCertUploaded;
    _data["EncryptionCertUploaded"] = this.EncryptionCertUploaded;
    _data["OnBehalfOf"] = this.OnBehalfOf;
    _data["SignatureCertUploaded"] = this.SignatureCertUploaded;
    _data["UserId"] = this.UserId;
    return _data;
  }
}
