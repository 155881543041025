var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { templateTitle } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import {
  DATE_FORMAT_OPTIONS,
  DECIMAL_SYMBOL_TYPE_OPTIONS,
  DEFAULT_CREATE_DATA_TEMPLATE,
  DELIMITER_OPTIONS,
  TEMPLATE_FORMAT_OPTIONS
} from "./CustomDataTemplateHelper";
import classNames from "classnames";
export function useCreateDataTemplateStep(props) {
  const isCreateDataStepInfoChanged = () => (initialCreateDataStepInfo == null ? void 0 : initialCreateDataStepInfo.decimalSeparator) !== (createDataStepInfo == null ? void 0 : createDataStepInfo.decimalSeparator) || (initialCreateDataStepInfo == null ? void 0 : initialCreateDataStepInfo.delimitier) !== (createDataStepInfo == null ? void 0 : createDataStepInfo.delimitier) || (initialCreateDataStepInfo == null ? void 0 : initialCreateDataStepInfo.hasHeaderLine) !== (createDataStepInfo == null ? void 0 : createDataStepInfo.hasHeaderLine) || (initialCreateDataStepInfo == null ? void 0 : initialCreateDataStepInfo.isAllCountryInOneFile) !== (createDataStepInfo == null ? void 0 : createDataStepInfo.isAllCountryInOneFile) || (initialCreateDataStepInfo == null ? void 0 : initialCreateDataStepInfo.isSaleAndPurchaseInOneFile) !== (createDataStepInfo == null ? void 0 : createDataStepInfo.isSaleAndPurchaseInOneFile) || (initialCreateDataStepInfo == null ? void 0 : initialCreateDataStepInfo.templateType) !== (createDataStepInfo == null ? void 0 : createDataStepInfo.templateType) || (initialCreateDataStepInfo == null ? void 0 : initialCreateDataStepInfo.formatSettingsDateFormat) !== (createDataStepInfo == null ? void 0 : createDataStepInfo.formatSettingsDateFormat);
  const [createDataStepInfo, setCreateDataStepInfo] = useState(DEFAULT_CREATE_DATA_TEMPLATE);
  const [initialCreateDataStepInfo, setInitialCreateDataStepInfo] = useState(DEFAULT_CREATE_DATA_TEMPLATE);
  useEffect(() => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _i;
    if (props.generationConfiguration) {
      const createDataStepObject = {
        templateType: (_a = props.generationConfiguration.AvailableTemplates.find((template2) => TEMPLATE_FORMAT_OPTIONS.find((o) => o.value === template2))) != null ? _a : DEFAULT_CREATE_DATA_TEMPLATE.templateType,
        delimitier: (_c = (_b = props.generationConfiguration.FormatSettings) == null ? void 0 : _b.Delimiter) != null ? _c : DEFAULT_CREATE_DATA_TEMPLATE.delimitier,
        decimalSeparator: (_e = (_d = props.generationConfiguration.FormatSettings) == null ? void 0 : _d.DecimalSeparator) != null ? _e : DEFAULT_CREATE_DATA_TEMPLATE.decimalSeparator,
        hasHeaderLine: (_g = (_f = props.generationConfiguration.FormatSettings) == null ? void 0 : _f.IncludesHeader) != null ? _g : DEFAULT_CREATE_DATA_TEMPLATE.hasHeaderLine,
        isSaleAndPurchaseInOneFile: !props.generationConfiguration.SalePurchaseSeparately,
        isAllCountryInOneFile: props.generationConfiguration.ComplianceUploadGenerationMode === "AllCountryInOne",
        companyId: props.generationConfiguration.CompanyId,
        formatSettingsDateFormat: (_i = (_h = props.generationConfiguration.FormatSettings) == null ? void 0 : _h.DateFormat) != null ? _i : DEFAULT_CREATE_DATA_TEMPLATE.formatSettingsDateFormat
      };
      setCreateDataStepInfo(createDataStepObject);
      setInitialCreateDataStepInfo(createDataStepObject);
    }
  }, [props.generationConfiguration]);
  const formatOfTheTemplateInputTemplate = () => html`
		<dc-multiswitch
			.label=${i18next.t("What is the format of the template?")}
			.customLabelClass=${"block"}
			.dataSource=${TEMPLATE_FORMAT_OPTIONS}
			.selectedItem=${createDataStepInfo.templateType}
			@change=${(e) => setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), { templateType: e.detail.value }))}
		></dc-multiswitch>
	`;
  const delimiterTypeInputTemplate = () => html`
		<dc-multiswitch
			class=${classNames({ hidden: createDataStepInfo.templateType !== "Dsv" })}
			.label=${i18next.t("Select the delimiter type")}
			.customLabelClass=${"block"}
			.dataSource=${DELIMITER_OPTIONS}
			.selectedItem=${createDataStepInfo.delimitier}
			@change=${(e) => setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), { delimitier: e.detail.value }))}
		></dc-multiswitch>
	`;
  const numberDecimalSymbolInputTemplate = () => html`
		<dc-multiswitch
			.label=${i18next.t("Number decimal symbol")}
			.customLabelClass=${"block"}
			.dataSource=${DECIMAL_SYMBOL_TYPE_OPTIONS}
			.selectedItem=${createDataStepInfo.decimalSeparator}
			@change=${(e) => setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), { decimalSeparator: e.detail.value }))}
		></dc-multiswitch>
	`;
  const headerLineInputTemplate = () => html`
		<dc-multiswitch
			.label=${i18next.t("Does the template include header line?")}
			.customLabelClass=${"block"}
			.dataSource=${[
    { label: "Yes", value: true },
    { label: "No", value: false }
  ]}
			.selectedItem=${createDataStepInfo.hasHeaderLine}
			@change=${(e) => setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), { hasHeaderLine: e.detail.value }))}
		></dc-multiswitch>
	`;
  const dateFormatInputTemplate = () => html`
		<dc-select
			class="max-w-[400px]"
			.label=${i18next.t("What will be the date format used in the source files?")}
			.customLabelClass=${"block mb-2"}
			.dataSource=${DATE_FORMAT_OPTIONS.map((o) => ({ label: o.label, value: o.value }))}
			.selectedValues=${createDataStepInfo.formatSettingsDateFormat}
			@change=${(e) => setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), { formatSettingsDateFormat: e.detail.selectedValue }))}
		></dc-select>
	`;
  const salePurchaseSeparatelyInputTemplate = () => html`
		<dc-multiswitch
			.label=${i18next.t("Will you provide Sale and Purchase transactions in separate files or in one file?")}
			.customLabelClass=${"block"}
			.dataSource=${[
    { label: "Separate file", value: false },
    { label: "One file", value: true }
  ]}
			.selectedItem=${createDataStepInfo.isSaleAndPurchaseInOneFile}
			@change=${(e) => setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), { isSaleAndPurchaseInOneFile: e.detail.value }))}
		></dc-multiswitch>
	`;
  const transactionsInputTemplate = () => html`
		<dc-multiswitch
			class=${classNames({ hidden: createDataStepInfo.templateType === "SAPText" })}
			.label=${i18next.t("Will you provide transactions by country or all country transactions in one file?")}
			.customLabelClass=${"block"}
			.dataSource=${[
    { label: "By country", value: false },
    { label: "All country", value: true }
  ]}
			.selectedItem=${createDataStepInfo.isAllCountryInOneFile}
			@change=${(e) => setCreateDataStepInfo(__spreadProps(__spreadValues({}, createDataStepInfo), { isAllCountryInOneFile: e.detail.value }))}
		></dc-multiswitch>
	`;
  const technicalInformationTemplate = () => html`
		<div class="text-xl font-semibold">${i18next.t("Technical information")}</div>
		<div class="flex gap-4 flex-wrap">${formatOfTheTemplateInputTemplate()} ${delimiterTypeInputTemplate()}</div>
		${numberDecimalSymbolInputTemplate()} ${headerLineInputTemplate()} ${dateFormatInputTemplate()}
	`;
  const uploadMethodsTemplate = () => html`
		<div class="text-xl font-semibold">${i18next.t("Upload methods")}</div>
		${salePurchaseSeparatelyInputTemplate()} ${transactionsInputTemplate()}
	`;
  const nextButtonTemplate = () => html`
		<div class="flex justify-start">
			${PrimaryButton({
    class: "w-32 my-6",
    text: i18next.t("Next"),
    onClick: () => __async(this, null, function* () {
      if (props.generationConfiguration && isCreateDataStepInfoChanged()) {
        yield props.updateCustomDataTemplate(createDataStepInfo, props.loader);
      }
      props.setStep(1);
    })
  })}
		</div>
	`;
  const template = () => html` <div class="m-8 flex flex-col gap-4">${templateTitle(i18next.t("Create Data Template"))} ${technicalInformationTemplate()} ${uploadMethodsTemplate()}${nextButtonTemplate()}</div> `;
  return { template };
}
